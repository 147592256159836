import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { NgxSpinnerService } from "ngx-spinner";
import { WorkflowService } from "src/app/shared/services/workflow/workflow.service";
import { getFormErrors } from "src/app/shared/utils/common";
import { WorkflowCategory } from "src/app/shared/models/workflow";

@Component({
  selector: "app-workflow-create-step",
  templateUrl: "./workflow-create-step.component.html",
  styleUrls: ["./workflow-create-step.component.scss"],
})
export class WorkflowCreateStepComponent implements OnInit {
  @Input() active: boolean = false;
  @Input() isEdit: boolean = false;
  @Input() workflowForm: UntypedFormGroup = {} as UntypedFormGroup;
  @Output() submitEvent = new EventEmitter();

  isLoading: boolean = true;
  showCategoryForm: boolean = false;

  constructor(
    private location: Location,
    private router: Router,
    private workflowService: WorkflowService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.isLoading = false;
  }

  getErrorMessage(field: string | null): string {
    return getFormErrors(field, this.workflowForm);
  }

  onCancel(): void {
    this.location.back();
  }

  get categories() {
    return this.workflowService.workflowCategories.filter(
      (category: WorkflowCategory) => {
        return category.is_active;
      },
    );
  }

  onCreateACategoryClicked() {
    // More fields for the form.
    this.workflowForm.addControl(
      "category_name",
      new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(255),
      ]),
    );
    this.workflowForm.addControl(
      "category_description",
      new UntypedFormControl("", [
        Validators.required,
        Validators.maxLength(10000),
      ]),
    );

    this.workflowForm.removeControl("category");
    this.showCategoryForm = true;
  }

  onWorkflowSave(): void {
    if (!this.workflowForm.valid) {
      return;
    }
    this.submitEvent.emit(this.workflowForm);
  }
}
