import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { WorkflowForm } from "src/app/shared/models/workflow";
import { WorkflowService } from "src/app/shared/services/workflow/workflow.service";
import { setFormErrors } from "src/app/shared/utils/common";

@Component({
  selector: "app-run-workflow-modal",
  templateUrl: "./run-workflow-modal.component.html",
  styleUrls: ["./run-workflow-modal.component.scss"],
})
export class RunWorkflowModalComponent implements OnInit {
  isSubmitBtnLoading: boolean = false;
  workflowForm: WorkflowForm = {} as WorkflowForm;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    public dialogRef: MatDialogRef<RunWorkflowModalComponent>,
    private workflowService: WorkflowService,
  ) {}

  get data() {
    return this._data;
  }

  get workflow() {
    return this.data.workflow;
  }

  get prettifiedTitle() {
    return (
      this.workflow.title.charAt(0).toUpperCase() + this.workflow.title.slice(1)
    );
  }

  get form() {
    return this.workflowForm;
  }

  ngOnInit(): void {
    this.workflowService
      .getWorkflowForm(this.data.workflow.id)
      .then((workflowForm: WorkflowForm) => {
        this.workflowForm = workflowForm;
      });
  }

  onCancel = () => {
    this.dialogRef.close(null);
  };

  onSubmit = (form: any) => {
    if (form.invalid) return;

    this.isSubmitBtnLoading = true;
    this.workflowService
      .startWorkflow(this.workflow.id, { values: form.value })
      .then(() => {
        this.dialogRef.close(true);
      })
      .catch(err => {
        setFormErrors(err.error, form);
      })
      .finally(() => {
        this.isSubmitBtnLoading = false;
      });
  };
}
