import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

export interface DeleteModalData {
  item_name: string;
  item: any;
}
@Component({
  selector: "app-delete-modal",
  templateUrl: "./delete-modal.component.html",
  styleUrls: ["./delete-modal.component.scss"],
})
export class DeleteModalComponent {
  item_name: string = "";
  item: any = null;
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: DeleteModalData,
    public dialogRef: MatDialogRef<DeleteModalComponent>,
  ) {}

  get data() {
    return this._data;
  }

  get title() {
    return this.data.item_name;
  }

  onCancel(): void {
    // do nothing
    this.dialogRef.close(false);
  }

  onArchive(): void {
    // just pass a true value and handle the actions in the parent component
    this.dialogRef.close(true);
  }
}
