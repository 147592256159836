import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import {
  MetricsResolution,
  PolusQueryInterface,
} from "src/app/shared/models/metrics";
import { MetricsService } from "src/app/shared/services/metrics/metrics.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  dateSelected: string = "7";
  data: MetricsResolution = {} as MetricsResolution;
  isLoading: boolean = true;

  chartLoadedOnce: boolean = false;
  dataSource: PolusQueryInterface[] = [];

  constructor(
    private metricsService: MetricsService,
    private spinner: NgxSpinnerService,
  ) {}

  ngOnInit(): void {
    this.retrieveLastDaysResolutions();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onDateChanged(event: any): void {
    this.retrieveLastDaysResolutions();
  }

  retrieveLastDaysResolutions = () => {
    this.isLoading = true;
    this.spinner.show();
    this.metricsService
      .getLastDaysResolutionMetrics(this.dateSelected)
      .then((response: MetricsResolution) => {
        this.data = response;
        this.dataSource = this.data.polus_queries;
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch(err => {
        // TODO: Error handling.
      })
      .finally(() => {
        this.spinner.hide();
        this.isLoading = false;
        this.chartLoadedOnce = true;
      });
  };
}
