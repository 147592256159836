// backend urls.
export const baseUrl = (comesFromBackend: boolean = false) => {
  const { hostname } = window.location;
  if (hostname === "localhost") {
    return "http://localhost:8000";
  }
  if (comesFromBackend) {
    return "";
  }
  return "/api";
};

// Forms specific
export const setFormErrors = (errors: any, form: any) => {
  // Given the errors, parse them and assign them to the given form
  if ("non_field_errors" in errors) {
    form.setErrors({
      serverError: { non_field_errors: errors.non_field_errors },
    });
  } else if (Object.keys(errors).length !== 0) {
    for (const [key, value] of Object.entries(errors)) {
      if (Object.keys(form.controls).includes(key)) {
        form.controls[key].setErrors({
          serverError: value,
        });
      } else {
        form.setErrors({
          serverError: {
            ...form.serverError,
            non_field_errors: value,
          },
        });
      }
    }
  } else {
    throw new Error(`I don't know how to parse the error: ${errors}`);
  }
};

const _parseFormError = (field: string, form: any): string => {
  // Parses and returns the error which is returned from the backend for a specific field
  if (Array.isArray(form.controls[field].errors!.serverError)) {
    return form.controls[field].errors!.serverError.join("\n");
  } else {
    return form.controls[field].errors!.serverError;
  }
};

const _parseGenericFormError = (form: any): string => {
  // Parses and returns a generic error which is not specific for a form field
  if (Array.isArray(form.errors?.serverError?.non_field_errors)) {
    return form.errors.serverError.non_field_errors.join("\n");
  }
  return "";
};

export const getFormErrors = (field: string | null, form: any) => {
  // Returns either the custom validation error message or the backend error if exists
  // In case no field is provided its a generic error

  // generic errors
  if (!field) {
    return _parseGenericFormError(form);
  }

  // custom errors
  if (form.controls[field].hasError("required")) {
    return "*Required field";
  } else if (form.controls[field].hasError("email")) {
    return "Not a valid email";
    // backend errors
  } else if (form.controls[field].errors) {
    return _parseFormError(field, form);
  }
  return "";
};

export const calculateGraphItemHeight = (data: any[]) => {
  const GRAPH_ITEM_MARGIN = 30;

  return data.length * GRAPH_ITEM_MARGIN;
};

export const calculateAllGraphHeight = (data: any[]) => {
  const GRAPH_MARGIN = 100;

  return calculateGraphItemHeight(data) + GRAPH_MARGIN;
};
