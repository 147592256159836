<div *ngIf="active">
  <div class="title" i18n="workflow type step: Workflow Type">
    Workflow Type
    <img
      src="assets/images/info.svg"
      class="pointer"
      alt="tooltop workflow type"
    />
  </div>
  <div
    class="subtitle"
    i18n="
      workflow type step: Determine the type of this workflow. What does it do?
    "
  >
    Determine the type of this workflow. What does it do?
  </div>
  <div class="container">
    <div *ngFor="let type of workflowTypes; let index = index">
      <div
        class="box pointer"
        [class.selected]="selectedTypeIndex === index"
        (click)="selectTypeOfWorkflow(index)"
      >
        <div class="circle">
          <img src="assets/images/workflow/tick.svg" alt="tick" />
        </div>
        <div class="content">
          <span class="name">{{ type.name }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="actions">
    <a
      mat-flat-button
      class="pointer"
      i18n="workflow-type-step:Cancel button"
      (click)="onCancel()"
    >
      Cancel
    </a>
    <div class="right">
      <a
        mat-flat-button
        (click)="onBack()"
        class="pointer"
        i18n="workflow-type-step:Back button"
      >
        Back
      </a>
      <button
        mat-raised-button
        color="primary"
        i18n="workflow-type-step:Save button"
        [disabled]="selectedTypeIndex !== null ? false : true"
        (click)="onTypeSave()"
      >
        Next
      </button>
    </div>
  </div>
</div>
