import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  OnDestroy,
} from "@angular/core";
import { Output, EventEmitter } from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatFormFieldAppearance } from "@angular/material/form-field";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormField, FormFieldType } from "../../models/forms";
import { FormService } from "../../services/form.service";
import { getFormErrors } from "../../utils/common";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent implements OnInit, OnChanges, OnDestroy {
  public onFormValuesReceived: BehaviorSubject<UntypedFormGroup> =
    new BehaviorSubject<UntypedFormGroup>(new UntypedFormGroup({}));

  @Input() form_class: Array<string> | string | object = "";
  @Input() definition: FormField[] = [];
  @Input() values: { [key: string]: any } = {};
  @Input() title: string = "";
  @Input() submitText: string = "Submit";
  @Input() cancelText: string = "Cancel";
  @Input() isSubmitLoading: boolean = false;
  @Input() appearance: MatFormFieldAppearance = "fill";
  @Input() hasDots: boolean = false;
  @Input() isLastItem: boolean = false;
  @Input() submitTextAlignment: string = "left"; // left - right - center
  @Input() type: string = ""; // just "login" for now.

  @Output() cancelEvent = new EventEmitter();
  @Output() submitEvent = new EventEmitter();
  @Output() formStatus = new EventEmitter();
  @Output() formRef = new EventEmitter();

  /** control for the MatSelect filter keyword */
  public valuesFilterCtrl: UntypedFormControl = new UntypedFormControl("");

  form: UntypedFormGroup = new UntypedFormGroup({});

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(private formService: FormService) {}

  ngOnInit(): void {
    this.formRef.emit(this.form);
    this.valuesFilterCtrl.setValue("");

    // Ok, just register a service with a valueChange subscription
    this.form.valueChanges.subscribe(() => {
      this.formService.onFormValuesReceived.next(this.form);
      this.formStatus.emit(this.form.value);
    });

    this.valuesFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filteredValues;
      });
  }

  filteredValues = (field: FormField): string[] => {
    if (!field.values) {
      return [];
    }

    return field.values.filter((value: string) => {
      return value
        .toLowerCase()
        .includes(this.valuesFilterCtrl.value.toLowerCase());
    });
  };

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.definition?.isFirstChange() ||
      changes?.definition?.previousValue !== changes.definition.currentValue
    ) {
      this.form = this.buildReactiveForm();
    }
  }

  buildReactiveForm = (): UntypedFormGroup => {
    // Build in a dynamic way a form described from the backend
    let form_items: { [key: string]: AbstractControl } = {};
    for (let field of this.definition) {
      form_items = {
        ...form_items,
        [field.name]: new UntypedFormControl(
          this.values[field.name] || this.buildDefaultValue(field),
          this.buildFormValidators(field),
        ),
      };
    }
    let form: UntypedFormGroup = new UntypedFormGroup(form_items);
    return form;
  };

  buildDefaultValue = (field: FormField): any => {
    // Based on forms type return an accepted default value
    if (field.type === FormFieldType.BOOL) return false;
    else return "";
  };

  buildFormValidators = (field: FormField): Array<ValidatorFn> => {
    // Based on form's atrribute extract the correct validators
    let validators = [];
    if (field.required) {
      validators.push(Validators.required);
    }
    return validators;
  };

  display_value(field: FormField) {
    return field.display_name || field.name;
  }

  input_type(type: string): string {
    switch (type) {
      case "str" || "address":
        return "text";
      case "int":
        return "number";
      default:
        return type;
    }
  }

  getErrorMessage(field: string | null): string {
    return getFormErrors(field, this.form);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSubmit(event: any): void {
    if (!this.form.valid) return;
    this.submitEvent.emit(this.form);
  }

  onCancel(event: any): void {
    event.preventDefault(); // Prevent any default action like submitting the form.
    this.cancelEvent.emit();
  }
}
