import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Location } from "@angular/common";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { getFormErrors } from "src/app/shared/utils/common";

const OPTIONS = ["Immediately"];

@Component({
  selector: "app-workflow-kick-off-step",
  templateUrl: "./workflow-kick-off-step.component.html",
  styleUrls: ["./workflow-kick-off-step.component.scss"],
})
export class WorkflowKickOffStepComponent implements OnInit {
  @Input() active: boolean = false;
  @Output() submitEvent = new EventEmitter();
  @Output() backEvent = new EventEmitter();

  kickOffForm = new UntypedFormGroup({
    option: new UntypedFormControl(OPTIONS[0], [Validators.required]),
  });

  constructor(private location: Location) {}

  getErrorMessage(field: string | null): string {
    return getFormErrors(field, this.kickOffForm);
  }

  ngOnInit(): void {
    // Initialize the form value.
    this.kickOffForm.controls.option.setValue(OPTIONS[0]);
  }

  get options() {
    return OPTIONS;
  }

  onCancel = () => {
    this.location.back();
  };

  onBack = () => {
    this.backEvent.emit();
  };

  onKickOffSave(): void {
    this.kickOffForm.markAsDirty();
    if (this.kickOffForm.invalid) return;

    this.submitEvent.emit(this.kickOffForm.value);
  }
}
