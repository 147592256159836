<div>
  <span i18n="workflows:workflow-category title" class="gaspar-title">
    <div class="aligned">
      {{ title }}
      <img src="assets/images/workflow/workflow-category-black.svg" /></div
  ></span>

  <div class="content">
    <span
      class="content-title"
      i18n="workflows:workflow no workflow or category found"
    >
      A category contains workflows with shared characteristics.
    </span>

    <div class="workflow-category-form">
      <form
        class="gaspar-form"
        [formGroup]="categoryForm"
        (ngSubmit)="onSave()"
      >
        <mat-dialog-content>
          <div class="sub-header">Give this category a name</div>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label i18n="workflow-category:category form name">
              Name your Category
            </mat-label>
            <input matInput formControlName="name" />
            <mat-error *ngIf="categoryForm.controls.name.invalid">
              {{ getErrorMessage("name") }}
            </mat-error>
          </mat-form-field>
          <div class="sub-header">Describe the purpose of this category</div>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label i18n="workflow-category:category form description">
              Description
            </mat-label>
            <textarea matInput formControlName="description" rows="8">
            </textarea>
            <mat-error *ngIf="categoryForm.controls.description.invalid">
              {{ getErrorMessage("description") }}
            </mat-error>
          </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-actions class="actions">
          <div style="display: flex; justify-content: space-around">
            <a
              mat-flat-button
              class="pointer"
              i18n="workflow-category:Cancel button"
              (click)="onCancel()"
            >
              Cancel
            </a>
            <button
              mat-raised-button
              color="primary"
              i18n="workflow-category:Save button"
            >
              Save
            </button>
          </div>
        </mat-dialog-actions>
      </form>
    </div>
  </div>
</div>
