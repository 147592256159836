import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TagType } from "src/app/shared/models/tagType";
import { TagService } from "src/app/shared/services/tag/tag.service";
import { getFormErrors, setFormErrors } from "src/app/shared/utils/common";

export interface TagTypeModalData {
  tagType?: TagType;
  isEditAction: boolean;
}

@Component({
  selector: "app-tag-type-modal",
  templateUrl: "./tag-type-modal.component.html",
  styleUrls: ["./tag-type-modal.component.scss"],
})
export class TagTypeModalComponent implements OnInit {
  tagTypeForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [Validators.required]),
    description: new UntypedFormControl(null, []),
    unique: new UntypedFormControl(false, [Validators.required]),
    userSelectable: new UntypedFormControl(false, [Validators.required]),
  });

  // Color is handled as a simple field
  color: string = "#000000";

  isLoading: boolean = false;
  data: TagTypeModalData = {} as TagTypeModalData;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: TagTypeModalData,
    public dialogRef: MatDialogRef<TagTypeModalComponent>,
    private tagService: TagService,
  ) {
    this.data = _data;
  }

  get isEditable(): boolean {
    let editable: boolean = true;
    if (!this.data.isEditAction) return editable;

    if (this.data.tagType) {
      editable = this.data.tagType.can_add_tags!;
    }
    return editable;
  }

  getErrorMessage(field: string | null): string {
    return getFormErrors(field, this.tagTypeForm);
  }

  ngOnInit(): void {
    // If we are in edit mode, initialize the values
    if (this.data.isEditAction && this.data.tagType) {
      this.tagTypeForm.setValue({
        name: this.data.tagType.name,
        description: this.data.tagType.description,
        unique: this.data.tagType.unique,
        userSelectable: this.data.tagType.user_selectable,
      });
      this.color = this.data.tagType.color;

      // Disabled fields
      if (this.isEditable) {
        this.tagTypeForm.controls.name.disable();
        this.tagTypeForm.controls.description.disable();
        this.tagTypeForm.controls.unique.disable();
      }
    }
  }

  dataToSubmit() {
    // If the tag type can't add tags, backend expects only color and selectable fields
    const { tagType } = this.data;
    if (tagType && tagType.can_add_tags) {
      return {
        name: this.tagTypeForm.controls.name.value,
        description: this.tagTypeForm.controls.description.value,
        unique: this.tagTypeForm.controls.unique.value,
        user_selectable: this.tagTypeForm.controls.userSelectable.value,
        color: this.color,
      };
    } else {
      return {
        user_selectable: this.tagTypeForm.controls.userSelectable.value,
        color: this.color,
      };
    }
  }

  onCancel() {
    // Close the modal
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.tagTypeForm.invalid) return;

    this.isLoading = true;
    // submit the tag type create modal
    if (this.data.isEditAction) {
      // Edit action
      let data = this.dataToSubmit();
      this.tagService
        .editTagType(this.data.tagType!.name, data)
        .then(() => {
          this.dialogRef.close();
        })
        .catch(err => {
          setFormErrors(err.error, this.tagTypeForm);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      // Create Action
      this.tagService
        .createTagType({
          name: this.tagTypeForm.controls.name.value,
          color: this.color,
          unique: this.tagTypeForm.controls.unique.value,
          user_selectable: this.tagTypeForm.controls.userSelectable.value,
        })
        .then(() => {
          this.dialogRef.close();
        })
        .catch(err => {
          setFormErrors(err.error, this.tagTypeForm);
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
}
