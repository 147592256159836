import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { Router } from "@angular/router";
import { catchError } from "rxjs/operators";

const EXCLUDED_PATHS_FOR_5X_ERRORS = ["integrations"];

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  private toErrorPage(errorMessage: string, backButton: boolean) {
    // Navigates to the error page and passes the given error message to show.
    this.router.navigateByUrl("error", {
      state: {
        error: errorMessage,
        backButton: backButton,
      },
    });
  }
  private handleErrors(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401 || err.status === 403) {
      let errorMessage = `You are not authorized to access this resource, please contact with your administrator to grant you access. \n\n Information: ${err.error.detail}`;
      this.toErrorPage(errorMessage, true);
      return of(err.message); // or EMPTY may be appropriate here
    } else if (
      err.status === 500 &&
      !EXCLUDED_PATHS_FOR_5X_ERRORS.includes("integrations")
    ) {
      let errorMessage = `Something went wrong with our service. Please wait a few seconds and try again. \n\n Information: ${
        err.error.detail || JSON.stringify(err.error)
      }`;
      this.toErrorPage(errorMessage, false);
      return of(err);
    }
    return throwError(err);
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // Interceptor which handles some of the bad responses that may occured.
    // 1. Handles the 500 requests
    // 2. Handles the unauthrized requests
    return next
      .handle(request.clone())
      .pipe(catchError(x => this.handleErrors(x)));
  }
}
