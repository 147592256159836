import { Component, AfterViewInit, ViewChild, OnInit } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { IPaginatedUser, User, UserRole } from "src/app/shared/models/user";

import * as moment from "moment";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { EditExpertiseModalComponent } from "./components/edit-expertise-modal/edit-expertise-modal.component";
import { UserService } from "src/app/shared/services/user/user.service";
import { GASPAR } from "src/app/shared/constants/gaspar";
import { RerpObject } from "src/app/shared/models/common";
import { MatSelectChange } from "@angular/material/select";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

const DEBOUNCE_TIME = 500; // ms

@Component({
  selector: "app-user-management",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"],
})
export class UserManagementComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort) sort!: MatSort;
  selectedTab: string = UserRole.MANAGER;
  gasparRoles: RerpObject[] = GASPAR.USER_ROLE_OPTIONS;
  searchForm: UntypedFormGroup = new UntypedFormGroup({
    search: new UntypedFormControl(""),
  });

  displayedColumns: string[] = [
    "name",
    "role",
    "email",
    "last-activity",
    "expertise",
    "actions",
  ];
  dataSource: MatTableDataSource<any> = new MatTableDataSource(undefined);
  paginatorPageIndex: number = 0;
  paginatedUsers: IPaginatedUser | null = null;

  paginatedUsersSubject: Subject<User[]> = new Subject();

  constructor(private dialog: MatDialog, private userService: UserService) {}

  ngOnInit(): void {
    this.retrievePaginatedUser();

    this.searchForm
      .get("search")
      ?.valueChanges.pipe(debounceTime(DEBOUNCE_TIME))
      .subscribe((criteria: string) => {
        this.paginatedUsers = null;

        if (criteria) {
          this.userService.searchUser(criteria).then((users: User[]) => {
            this.paginatedUsersSubject.next(users);
          });
        } else {
          this.retrievePaginatedUser();
        }
      });
    this.dataSource.filter = this.searchForm
      .get("search")
      ?.value.trim()
      .toLowerCase();

    this.paginatedUsersSubject.subscribe((users: User[]) => {
      this.dataSource = new MatTableDataSource<User>(users);
    });
  }

  ngAfterViewInit(): void {
    // The condition does nothing, except the part that we pass the lint errors!
    this.dataSource.sort = this.sort;
  }

  retrievePaginatedUser = () => {
    this.userService
      .getUsers(
        this.filterUserRole(),
        this.filterStatus(),
        this.paginatorPageIndex + 1,
      )
      .then((users: IPaginatedUser) => {
        this.paginatedUsers = users;
        this.paginatedUsersSubject.next(users.results);
      });
  };

  onPageChange = (event: PageEvent) => {
    this.paginatorPageIndex = event.pageIndex;
    this.retrievePaginatedUser();
  };

  userRoleChanged = (user: User, event: MatSelectChange) => {
    // Change user's role
    // Keep lint happy.
    if (!user.id) return;
    // TODO: Error handling
    this.userService.updateUserRole(user.id, event.value as UserRole);
  };

  openModal = (user: User) => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "gaspar-modal";
    dialogConfig.data = {
      user,
    };
    const dialogRef = this.dialog.open(
      EditExpertiseModalComponent,
      dialogConfig,
    );

    dialogRef.afterClosed().subscribe(() => {
      this.retrievePaginatedUser();
    });
  };

  onSelectedTabChange = (value: string): boolean => {
    return this.selectedTab === value;
  };

  onChangeUserType = (value: string): void => {
    this.paginatorPageIndex = 0;
    this.selectedTab = value;
    this.retrievePaginatedUser();
  };

  filterUserRole = () => {
    if (this.selectedTab === "ARCHIVED") {
      return "";
    } else {
      return this.selectedTab;
    }
  };

  filterStatus = () => {
    if (this.selectedTab === "ARCHIVED") {
      return false;
    } else {
      return true;
    }
  };

  lastActivityPrettified = (user: User) => {
    if (user.last_active_at) return moment(user.last_active_at).fromNow();
    else return "-";
  };
}
