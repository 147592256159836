import { Component, Inject, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { EditTag, Tag } from "src/app/shared/models/tag";
import { TagType } from "src/app/shared/models/tagType";
import { TagService } from "src/app/shared/services/tag/tag.service";
import { getFormErrors, setFormErrors } from "src/app/shared/utils/common";
import {
  getDurationFromSLA,
  getDurationString,
} from "src/app/shared/utils/sla/sla";

export interface TagTypeModalData {
  tagType?: TagType;
  tag?: Tag;
  isEditAction: boolean;
}
@Component({
  selector: "app-tag-modal",
  templateUrl: "./tag-modal.component.html",
  styleUrls: ["./tag-modal.component.scss"],
})
export class TagModalComponent implements OnInit {
  tagForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [Validators.required]),
    description: new UntypedFormControl("", [Validators.required]),
    closes: new UntypedFormControl(false, [Validators.required]),
    respondWithin: new UntypedFormControl({ value: "", disabled: true }),
    resolveWithin: new UntypedFormControl({ value: "", disabled: true }),
  });

  hasResolveIn: boolean = false;
  hasRespondWithin: boolean = false;
  respondWithinUnit: string = "m";
  resolveWithinUnit: string = "m";

  isLoading: boolean = false;
  isArchiving: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    public dialogRef: MatDialogRef<TagModalComponent>,
    private tagService: TagService,
  ) {}

  ngOnInit(): void {
    if (this.data.isEditAction) {
      const { name, description, sla_assign, sla_resolve, closes } =
        this.data.tag;

      this.hasResolveIn = sla_resolve ? true : false;
      this.hasRespondWithin = sla_assign ? true : false;

      this.tagForm.setValue({
        name: name,
        description: description,
        closes: closes,
        resolveWithin: sla_resolve ? getDurationFromSLA(sla_resolve).value : "",
        respondWithin: sla_assign ? getDurationFromSLA(sla_assign).value : "",
      });

      if (sla_resolve) this.tagForm.controls.resolveWithin.enable();
      if (sla_assign) this.tagForm.controls.respondWithin.enable();

      this.respondWithinUnit = sla_assign
        ? getDurationFromSLA(sla_assign).unit
        : "";
      this.resolveWithinUnit = sla_resolve
        ? getDurationFromSLA(sla_resolve).unit
        : "";

      // Disabled fields
      if (this.isEditable) {
        this.tagForm.controls.name.disable();
        this.tagForm.controls.description.disable();
        this.tagForm.controls.closes.disable();
      }
    }
  }

  toggleArchiveTag = () => {
    // Toggles the state of a tag, either archived or unarchived.
    this.isArchiving = true;
    const { tag } = this.data;
    let updatedTag: EditTag = {
      ...tag,
      is_active: !tag.is_active,
      type: this.data.tag.type.id,
    };
    delete updatedTag.name;

    this.tagService
      .editTag(tag.slug, updatedTag)
      .then(() => {
        this.dialogRef.close();
      })
      .catch(err => {
        setFormErrors(err.error, this.tagForm);
      })
      .finally(() => {
        this.isArchiving = false;
      });
  };

  getErrorMessage(field: string | null): string {
    return getFormErrors(field, this.tagForm);
  }

  onCancel(): void {
    // do nothing
    this.dialogRef.close();
  }

  get data() {
    // The data passed in the modal
    return this._data;
  }

  get isEditable(): boolean {
    let editable: boolean = true;
    if (!this.data.isEditAction) return editable;

    if (this.data.tagType) {
      editable = !!this.data.tagType.is_system;
    }
    return editable;
  }

  dataToSubmit = (
    resolveWithin: string | null,
    respondWithin: string | null,
  ): EditTag => {
    let updatedTag: EditTag = {} as EditTag;

    if (this.data.tagType.is_system) {
      updatedTag = {
        sla_assign: respondWithin || null,
        sla_resolve: resolveWithin || null,
      };
    } else {
      updatedTag = {
        ...this.data.tag,
        name: this.tagForm.controls.name.value,
        description: this.tagForm.controls.description.value,
        type: this.data.tag.type.id,
        closes: this.tagForm.controls.closes.value,
        sla_assign: respondWithin || null,
        sla_resolve: resolveWithin || null,
      };
    }
    if (updatedTag.name === this.data.tag.name) {
      delete updatedTag.name;
    }
    return updatedTag;
  };

  editSubmitAction(
    resolveWithin: string | null,
    respondWithin: string | null,
  ): void {
    // If the name is the same backend doesn't accept it as it awaits a new value to assign
    let updatedTag: EditTag = this.dataToSubmit(resolveWithin, respondWithin);
    this.tagService
      .editTag(this.data.tag.slug, updatedTag)
      .then(() => {
        this.dialogRef.close();
      })
      .catch(err => {
        setFormErrors(err.error, this.tagForm);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  createSubmitAction(
    resolveWithin: string | null,
    respondWithin: string | null,
  ): void {
    // Save the tag
    this.tagService
      .createTag({
        name: this.tagForm.controls.name.value,
        description: this.tagForm.controls.description.value,
        type: this.data.tagType.id,
        closes: this.tagForm.controls.closes.value,
        sla_assign: respondWithin || null,
        sla_resolve: resolveWithin || null,
      })
      .then(() => {
        this.dialogRef.close();
      })
      .catch(err => {
        setFormErrors(err.error, this.tagForm);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  onSave(): void {
    if (this.tagForm.invalid) return;

    this.isLoading = true;
    let resolveWithin = null;
    if (this.hasResolveIn) {
      resolveWithin = getDurationString(
        parseInt(this.tagForm.controls.resolveWithin.value || "0", 10),
        this.resolveWithinUnit,
      );
    }

    let respondWithin = null;
    if (this.hasRespondWithin) {
      respondWithin = getDurationString(
        parseInt(this.tagForm.controls.respondWithin.value || "0", 10),
        this.respondWithinUnit,
      );
    }

    if (this.data.isEditAction) {
      this.editSubmitAction(resolveWithin, respondWithin);
    } else {
      this.createSubmitAction(resolveWithin, respondWithin);
    }
  }

  unitChangedRespondWithIn = (value: string): void => {
    this.respondWithinUnit = value;
  };

  unitChangedResolveWithIn = (value: string): void => {
    this.resolveWithinUnit = value;
  };

  onToggleRespondWith = (event: MatSlideToggleChange) => {
    this.hasRespondWithin = event.checked;
    this.tagForm.controls.respondWithin.setValue("");
    if (this.hasRespondWithin) {
      this.tagForm.controls.respondWithin.enable();
      this.respondWithinUnit = "m";
    } else {
      this.tagForm.controls.respondWithin.disable();
    }
  };

  onToggleResolveIn = (event: MatSlideToggleChange) => {
    this.hasResolveIn = event.checked;
    this.tagForm.controls.resolveWithin.setValue("");
    if (this.hasResolveIn) {
      this.tagForm.controls.resolveWithin.enable();
      this.resolveWithinUnit = "m";
    } else {
      this.tagForm.controls.resolveWithin.disable();
    }
  };
}
