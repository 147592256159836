import { Tag } from "./tag";

export interface IPaginatedUser {
  count: number;
  next: string;
  previous: string;
  results: User[];
}
export interface User {
  id?: number;
  is_active?: boolean;
  last_active_at?: string | null;
  email: string;
  name: string;
  role: UserRole | string;
  tags: Tag[];
}

export enum UserRole {
  ENDUSER = "ENDUSER",
  AGENT = "AGENT",
  MANAGER = "MANAGER",
}
