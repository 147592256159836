import { Component, HostListener, Input, OnInit } from "@angular/core";
import { ChartType } from "angular-google-charts";
import { STORAGE_KEYS } from "src/app/shared/constants/localeStorageKeys";
import { WorkerMetricsInterface } from "src/app/shared/models/metrics";
import {
  calculateAllGraphHeight,
  calculateGraphItemHeight,
} from "src/app/shared/utils/common";

const SIDEBAR_WIDTH: number = 240;
const PAGE_MARGINS: number = 35;

@Component({
  selector: "app-column-workflows-chart",
  templateUrl: "./column-workflows-chart.component.html",
  styleUrls: ["./column-workflows-chart.component.scss"],
})
export class ColumnWorkflowsChartComponent implements OnInit {
  @Input() data: WorkerMetricsInterface = {} as WorkerMetricsInterface;

  chartLoadedOnce: boolean = false;

  title: string = "";
  type: ChartType = ChartType.BarChart;
  graphData: [string, number][] = [];
  columnNames = ["Automation name", "Requests resolved by Gaspar."];
  options = {
    colors: ["#9FC7EB"],
    fontSize: 12,
    legend: {
      position: "none",
    },
    hAxis: {
      minValue: 0,
      format: "0",
    },
    chartArea: {
      height: 0,
    },
  };
  width: any = 0;
  height: any = 350;
  hasData: boolean = false;

  constructor() {
    this.width = this.calcInnerWidth();
  }

  ngOnInit(): void {
    this.graphData = this.drawGraph(this.data);
    if (this.graphData && this.graphData.length !== 0) this.hasData = true;
    else this.hasData = false;

    this.options.chartArea.height = calculateGraphItemHeight(this.graphData);
    this.height = calculateAllGraphHeight(this.graphData);
    this.graphData.sort((a, b) => a[1] - b[1]);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChartReady(event: any): void {
    if (!this.chartLoadedOnce) this.chartLoadedOnce = true;
  }

  calcInnerWidth = (): number => {
    // Calculates the inner width for a responsive graph.
    if (localStorage.getItem(STORAGE_KEYS.SIDEBAR) == "close")
      return window.innerWidth - PAGE_MARGINS;
    else return window.innerWidth - SIDEBAR_WIDTH - PAGE_MARGINS;
  };

  @HostListener("window:resize", ["$event"])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onWindowResize(event: any) {
    this.width = this.calcInnerWidth();
  }

  drawGraph = (data: WorkerMetricsInterface): [string, number][] => {
    let graphData: [string, number][] = [];
    for (let item in data) {
      let tmp_key = item.replace("workflow_", "");
      if (data[item] > 0) {
        graphData.push([tmp_key, data[item]]);
      }
    }
    return graphData;
  };
}
