import { animate, style, transition, trigger } from "@angular/animations";
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-overlay",
  templateUrl: "./overlay.component.html",
  styleUrls: ["./overlay.component.scss"],
  animations: [
    trigger("fade", [
      transition("void => active", [
        // using status here for transition
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 })),
      ]),
      transition("* => void", [animate(200, style({ opacity: 0 }))]),
    ]),
  ],
})
export class OverlayComponent implements OnInit {
  @Input() active: boolean = true;
  constructor() {}

  ngOnInit(): void {}

  deactivateOverlay = () => {
    this.active = false;
  };

  cancelInnerProp = (event: any) => {
    event.stopPropagation();
  };
}
