import { Component } from "@angular/core";
import { Tag } from "src/app/shared/models/tag";
import { TagType } from "src/app/shared/models/tagType";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { TagTypeModalComponent } from "./components/tag-type-modal/tag-type-modal.component";
import { TagModalComponent } from "./components/tag-modal/tag-modal.component";
import { TagService } from "src/app/shared/services/tag/tag.service";
import { NgxSpinnerService } from "ngx-spinner";
import { DeleteModalComponent } from "src/app/shared/components/delete-modal/delete-modal.component";

@Component({
  selector: "app-tags",
  templateUrl: "./tags.component.html",
  styleUrls: ["./tags.component.scss"],
})
export class TagsComponent {
  selectedTab: string = "active"; // ["all", "system", "by-customers", "archived"]
  ellipsisIcon = faEllipsisV;
  _tagTypes: TagType[] = [];
  _tags: Tag[] = [];
  constructor(
    private dialog: MatDialog,
    private tagService: TagService,
    private spinner: NgxSpinnerService,
  ) {
    this.tags = this.tagService.tags;
    this.tagTypes = this.tagService.tagTypes;
  }

  set tags(tags: Tag[]) {
    this._tags = tags;
  }

  get tags() {
    return this._tags;
  }

  set tagTypes(tagTypes: TagType[]) {
    this._tagTypes = tagTypes;
  }

  get tagTypes() {
    switch (this.selectedTab) {
      case "system":
        return this._tagTypes.filter((tagType: TagType) => {
          return tagType.is_system && tagType.is_active;
        });
      case "by-customers":
        return this._tagTypes.filter((tagType: TagType) => {
          return tagType.user_selectable && tagType.is_active;
        });
      case "archived":
        return this._tagTypes.filter((tagType: TagType) => {
          return !tagType.is_active;
        });
      default:
        return this._tagTypes.filter((tagType: TagType) => {
          return tagType.is_active;
        });
    }
  }

  onSelectedTagChange = (value: string): boolean => {
    return this.selectedTab === value;
  };

  onChangeTag = (value: string): void => {
    this.selectedTab = value;
  };

  onNewTagTypeButtonClicked = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "gaspar-modal";
    dialogConfig.data = {
      isEditAction: false,
    };
    this.dialog.open(TagTypeModalComponent, dialogConfig);
  };

  tagsByType = (tagType: TagType): Tag[] => {
    let tags = [];
    let tagTypeName = tagType.name;
    for (let i = 0; i < this.tags.length; i++) {
      if (this.tags[i].type.name === tagTypeName) {
        tags.push(this.tags[i]);
      }
    }
    return tags;
  };

  tagsLengthByType = (tagType: TagType): string => {
    let tags = this.tagsByType(tagType);
    let tagStr = "tag";
    if (!tags.length) return "There are no tags available";

    if (tags.length > 1) tagStr = "tags";

    return `${tags.length} ${tagStr}`;
  };

  addNewTagButtonClicked = (tagType: TagType): void => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "gaspar-modal";
    dialogConfig.data = {
      tagType: tagType,
      isEditAction: false,
    };
    this.dialog.open(TagModalComponent, dialogConfig);
  };

  editTagButtonClicked = (tag: Tag): void => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "gaspar-modal";
    dialogConfig.data = {
      tagType: tag.type,
      tag: tag,
      isEditAction: true,
    };
    this.dialog.open(TagModalComponent, dialogConfig);
  };

  onArchive = (tagType: TagType): void => {
    // If the tag is not active, its unarchivement
    if (!tagType.is_active) {
      this.spinner.show();
      tagType.is_active = !tagType.is_active;
      this.tagService
        .editTagType(tagType.name, tagType)
        .then(() => {})
        .catch(() => {
          // TODO: Error handling
        })
        .finally(() => {
          this.spinner.hide();
        });
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = "gaspar-modal";
      dialogConfig.data = {
        item_name: "Tag Type",
        item: tagType,
      };
      const dialogRef = this.dialog.open(DeleteModalComponent, dialogConfig);
      dialogRef
        .afterClosed()
        .pipe()
        .subscribe((aggreed: boolean) => {
          if (aggreed) {
            this.spinner.show();
            tagType.is_active = !tagType.is_active;
            this.tagService
              .editTagType(tagType.name, tagType)
              .then(() => {})
              .catch(() => {
                // TODO: Error handling
              })
              .finally(() => {
                this.spinner.hide();
              });
          }
        });
    }
  };

  onEdit = (tagType: TagType): void => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "gaspar-modal";
    dialogConfig.data = {
      tagType: tagType,
      isEditAction: true,
    };
    this.dialog.open(TagTypeModalComponent, dialogConfig);
  };
}
