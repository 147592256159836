<!-- Create a workflow. -->
<div *ngIf="active">
  <div class="content">
    <div class="workflow-category-form">
      <form
        class="gaspar-form"
        [formGroup]="workflowForm"
        (ngSubmit)="onWorkflowSave()"
      >
        <mat-dialog-content>
          <div class="sub-header">Give this workflow a name</div>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label i18n="workflow:form name"> Workflow </mat-label>
            <input matInput formControlName="name" />
            <mat-error *ngIf="this.workflowForm.controls.name.invalid">
              {{ getErrorMessage("name") }}
            </mat-error>
          </mat-form-field>
          <div class="sub-header">
            Describe the purpose of this workflow and the desired outcome
          </div>
          <mat-form-field class="w-100" appearance="outline">
            <mat-label i18n="workflow:workflow form description">
              Description
            </mat-label>
            <textarea matInput formControlName="description" rows="8">
            </textarea>
            <mat-error *ngIf="this.workflowForm.controls.description.invalid">
              {{ getErrorMessage("description") }}
            </mat-error>
          </mat-form-field>
          <mat-form-field
            class="w-100"
            appearance="outline"
            *ngIf="!showCategoryForm"
          >
            <mat-label i18n="workflow:form category"> Category </mat-label>
            <mat-select formControlName="category">
              <mat-option>Clear selection</mat-option>
              <mat-option
                *ngFor="let category of categories"
                [value]="category.id"
              >
                {{ category.name }}
              </mat-option>
              <mat-option (click)="onCreateACategoryClicked()" *ngIf="!isEdit">
                <!-- Yeah, as an svg image, mat are kinda hard to be styled in our way. -->
                <img
                  src="assets/images/workflow/create-category-btn.svg"
                  alt="Create a new workflow category"
                />
              </mat-option>
            </mat-select>
            <mat-error *ngIf="this.workflowForm.controls.category.invalid">
              {{ getErrorMessage("category") }}
            </mat-error>
          </mat-form-field>
        </mat-dialog-content>
        <mat-dialog-content>
          <div *ngIf="showCategoryForm">
            <span
              class="content-title"
              i18n="workflows:workflow no workflow or category found"
            >
              Category
            </span>
            <div class="sub-header">Give this category a name</div>
            <mat-form-field class="w-100" appearance="outline">
              <mat-label i18n="workflow-category:category form name">
                Name your Category
              </mat-label>
              <input matInput formControlName="category_name" />
              <mat-error
                *ngIf="this.workflowForm.controls.category_name.invalid"
              >
                {{ getErrorMessage("category_name") }}
              </mat-error>
            </mat-form-field>
            <div class="sub-header">Describe the purpose of this category</div>
            <mat-form-field class="w-100" appearance="outline">
              <mat-label i18n="workflow-category:category form description">
                Description
              </mat-label>
              <textarea
                matInput
                formControlName="category_description"
                rows="8"
              >
              </textarea>
              <mat-error
                *ngIf="this.workflowForm.controls.category_description.invalid"
              >
                {{ getErrorMessage("category_description") }}
              </mat-error>
            </mat-form-field>
          </div>
        </mat-dialog-content>
        <mat-dialog-actions class="actions">
          <div class="flex" style="justify-content: space-between">
            <a
              mat-flat-button
              class="pointer"
              i18n="workflow-category:Cancel button"
              (click)="onCancel()"
            >
              Cancel
            </a>
            <button
              mat-raised-button
              color="primary"
              i18n="workflow-category:Save button"
            >
              Next
            </button>
          </div>
        </mat-dialog-actions>
      </form>
    </div>
  </div>
</div>
<!-- End of create a workflow. -->
