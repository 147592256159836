import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-status-bar",
  templateUrl: "./status-bar.component.html",
  styleUrls: ["./status-bar.component.scss"],
})
export class StatusBarComponent implements OnInit {
  @Input() totalSteps: number = 1;
  @Input() activeSteps: number = 1;

  constructor() {}

  ngOnInit(): void {}

  get totalStepsAsArray(): number[] {
    let array = [];
    for (let i = 0; i < this.totalSteps; i++) {
      array.push(i);
    }
    return array;
  }
}
