<form class="gaspar-form" (ngSubmit)="onSave()" [formGroup]="tagForm">
  <mat-dialog-content>
    <mat-dialog-header>
      <div i18n="tag modal:Create tag title" class="title">
        {{ data.isEditAction ? "Edit" : "Create" }} Tag
      </div>
      <hr />
    </mat-dialog-header>
    <div class="image-container">
      <img src="assets/images/tag-icon.svg" alt="tag" />
    </div>
    <mat-form-field class="w-100" appearance="fill">
      <mat-label i18n="tag modal:Tag name"> Tag name </mat-label>
      <input matInput formControlName="name" />
      <mat-error *ngIf="this.tagForm.controls.name.invalid">
        {{ getErrorMessage("name") }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="fill">
      <mat-label i18n="tag modal:Tag description"> Tag description </mat-label>
      <input matInput formControlName="description" />
      <mat-error *ngIf="this.tagForm.controls.description.invalid">
        {{ getErrorMessage("description") }}
      </mat-error>
    </mat-form-field>
    <section class="w-100" *ngIf="data.tagType.name === 'Status'">
      <mat-checkbox i18n="tag-create-modal:tag closes" formControlName="closes">
        This tag closes the ticket?
      </mat-checkbox>
    </section>
    <mat-error *ngIf="this.tagForm.invalid">
      {{ getErrorMessage(null) }}
    </mat-error>
    <div class="sla-section">
      <p class="sla-title" i18n="tag modal:Set SLA (optional)">
        Set SLA (optional)
      </p>
      <div class="items-container">
        <div class="item">
          <div class="sla-header">
            <p i18n="tag modal:respond within">Respond within</p>
            <app-mat-slide-toggle-icon
              color="primary"
              [checked]="hasRespondWithin"
              (change)="onToggleRespondWith($event)"
            ></app-mat-slide-toggle-icon>
          </div>
          <div class="sla-value">
            <input
              type="number"
              placeholder="#"
              name="#"
              maxlength="2"
              formControlName="respondWithin"
              [class.disabled]="!hasRespondWithin"
            />
            <button
              mat-raised-button
              type="button"
              color="primary"
              [class.selected]="hasRespondWithin && respondWithinUnit === 'm'"
              (click)="unitChangedRespondWithIn('m')"
            >
              m
            </button>
            <button
              mat-raised-button
              color="primary"
              type="button"
              [class.selected]="hasRespondWithin && respondWithinUnit === 'h'"
              (click)="unitChangedRespondWithIn('h')"
            >
              h
            </button>
            <button
              mat-raised-button
              color="primary"
              type="button"
              [class.selected]="hasRespondWithin && respondWithinUnit === 'd'"
              (click)="unitChangedRespondWithIn('d')"
            >
              d
            </button>
          </div>
        </div>
        <div class="item">
          <div class="sla-header">
            <p i18n="tag modal:Resolved within">Resolve within</p>
            <app-mat-slide-toggle-icon
              color="primary"
              [checked]="hasResolveIn"
              (change)="onToggleResolveIn($event)"
            ></app-mat-slide-toggle-icon>
          </div>
          <div class="sla-value">
            <input
              type="number"
              placeholder="#"
              name="#"
              maxlength="2"
              formControlName="resolveWithin"
              [class.disabled]="!hasResolveIn"
            />
            <button
              mat-raised-button
              type="button"
              color="primary"
              [class.selected]="hasResolveIn && resolveWithinUnit === 'm'"
              (click)="unitChangedResolveWithIn('m')"
            >
              m
            </button>
            <button
              mat-raised-button
              type="button"
              color="primary"
              [class.selected]="hasResolveIn && resolveWithinUnit === 'h'"
              (click)="unitChangedResolveWithIn('h')"
            >
              h
            </button>
            <button
              mat-raised-button
              type="button"
              color="primary"
              [class.selected]="hasResolveIn && resolveWithinUnit === 'd'"
              (click)="unitChangedResolveWithIn('d')"
            >
              d
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="actions">
    <div class="left" *ngIf="data.tag && !data.tag.is_system">
      <a
        *ngIf="data.tag.is_active"
        mat-raised-button
        color="warn"
        (click)="toggleArchiveTag()"
        [class.spinner]="isArchiving"
        i18n="tag modal:Archive button"
      >
        Archive
      </a>
      <a
        *ngIf="!data.tag.is_active"
        mat-button
        (click)="toggleArchiveTag()"
        [class.spinner]="isArchiving"
        i18n="tag modal:Unarchive button"
      >
        Unarchive
      </a>
    </div>

    <a mat-button (click)="onCancel()" i18n="tag modal:Cancel button">
      Cancel
    </a>
    <button
      mat-raised-button
      color="primary"
      i18n="tag modal:Save button"
      [class.spinner]="isLoading"
      [disabled]="isLoading"
    >
      Save
    </button>
  </mat-dialog-actions>
</form>
