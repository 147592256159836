import { Injectable } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FormService {
  public onFormValuesReceived: BehaviorSubject<UntypedFormGroup> =
    new BehaviorSubject<UntypedFormGroup>(new UntypedFormGroup({}));

  constructor() {}

  onDataReceived = (form: UntypedFormGroup) => {
    this.onFormValuesReceived.next(form);
  };
}
