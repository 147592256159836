import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { GASPAR } from "src/app/shared/constants/gaspar";
import { INTEGRATIONS } from "src/app/shared/mocks/integrations";
import { NormalResponse } from "src/app/shared/models/common";
import {
  Integration,
  IntegrationConfig,
  IntegrationInfo,
} from "src/app/shared/models/integration";
import { IntegrationService } from "src/app/shared/services/integration/integration.service";
import { baseUrl, setFormErrors } from "src/app/shared/utils/common";
import { WorkflowService } from "src/app/shared/services/workflow/workflow.service";
import { HttpErrorResponse } from "@angular/common/http";
import { URLS } from "src/app/shared/constants/urls";

@Component({
  selector: "app-integration",
  templateUrl: "./integration.component.html",
  styleUrls: ["./integration.component.scss"],
})
export class IntegrationComponent implements OnInit {
  @Input() integration: Integration = new Input();
  @Output() hasError = new EventEmitter<string>();

  info: IntegrationInfo | null = {} as IntegrationInfo;
  config: IntegrationConfig = {} as IntegrationConfig;

  arrowUp = faChevronUp;
  arrowDown = faChevronDown;
  panelOpenState = false;
  integrations = INTEGRATIONS;
  backend_url: string = "";
  isLoading: boolean = true;
  isSubmitButtonLoading: boolean = false;
  isIntegrationButtonLoading: boolean = false;
  error: string = "";

  constructor(
    private integrationService: IntegrationService,
    private workflowService: WorkflowService,
  ) {}

  get imageBaseUrl() {
    return baseUrl(true);
  }

  shouldShowTitle = (integrationCode: string) => {
    return !GASPAR.NON_TITLE_INTEGRATIONS.includes(integrationCode);
  };

  ngOnInit(): void {
    this.loadIntegrationInfo();
  }

  handle500Errors = (what: string, err: HttpErrorResponse) => {
    if (err.status === 500) {
      this.error = `Failed to load ${what} for ${this.integration.name}.\nPlease contact us at ${GASPAR.CONTACT_INFO} for further information.`;
      this.hasError.emit(this.integration.code);
    }
  };

  loadIntegrationInfo = () => {
    this.integrationService
      .getIntegration(baseUrl().replace("/api", "") + this.integration.url)
      .then((integration_status: IntegrationInfo | null) => {
        this.info = integration_status;
        // If the integration has config options, retrieve them
        if (this.showConfig) {
          this.integrationService
            .getIntegrationConfig(this.info!.config_url)
            .then((config: IntegrationConfig) => {
              this.config = config;
            })
            .catch((err: HttpErrorResponse) => {
              this.handle500Errors("configuration", err);
            })
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          this.isLoading = false;
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.handle500Errors("status", err);
      });
  };

  isGoogleActive = (code: string) => {
    if (!this.info) {
      return;
    }

    if (code === "gsuite" && !this.showDisableButton()) return true;
    else return false;
  };

  showDisableButton = () => {
    if (!this.info) {
      return;
    }
    return this.info.is_active;
  };

  showEnableButton = (code: string) => {
    if (!this.info) {
      return;
    }
    if (this.isGoogleActive(code)) return false;

    return !this.info.is_active && !this.info.is_simple;
  };

  showResetConfigurationButton = (code: string) => {
    return (
      this.info &&
      this.showEnableButton(code) &&
      this.info.show_reset_config_button
    );
  };

  showManualSyncKnowledgeButton = () => {
    return (
      this.info &&
      this.info.show_manual_sync_knowledge_button &&
      !this.info.show_manual_sync_knowledge_in_progress
    );
  };

  showManualSyncKnowledgeInProgress = () => {
    return this.info && this.info.show_manual_sync_knowledge_in_progress;
  };

  showWarningInfo = () => {
    if (this.info && this.info.is_active) {
      return (
        this.info!.last_token_refresh_http_status !== 200 &&
        this.info!.last_token_refresh_http_status != null
      );
    } else {
      return false;
    }
  };

  get showConfig() {
    if (!this.info) {
      return;
    }

    return (this.info.is_active || this.info.is_simple) && this.info.config_url;
  }

  togglePanel = (): void => {
    this.panelOpenState = !this.panelOpenState;
  };

  onConfigSubmit = (form: UntypedFormControl) => {
    this.isSubmitButtonLoading = true;
    this.integrationService
      .updateIntegrationConfig(this.info!.config_url, form.value)
      .then((res: NormalResponse) => {
        if (!res.ok) throw new Error(`Response is not ok: ${res}`);
        this.info!.is_active = true;
        this.togglePanel();
        this.loadIntegrationInfo();
      })
      .catch(err => {
        setFormErrors(err.error, form);
      })
      .finally(() => {
        this.isSubmitButtonLoading = false;
        //we need to invalidate the cache for the workers
        this.workflowService.invalidateWorkersState();
      });
  };

  onIntegrationEnable = () => {
    if (!this.info) {
      return;
    }
    window.open(this.info.url, "_self");
  };

  onIntegrationDisable = () => {
    this.isIntegrationButtonLoading = false;
    this.integrationService
      .deleteIntegration(this.integration.url)
      .then((data: NormalResponse) => {
        if (data.ok) {
          this.info!.is_active = false;
          this.loadIntegrationInfo();
        }
      })
      .finally(() => {
        this.isIntegrationButtonLoading = false;
        //we need to invalidate the cache for the workers
        this.workflowService.invalidateWorkersState();
      });
  };

  onManulSyncKnowledge = () => {
    this.integrationService
      .triggerManualSync(
        URLS.INTEGRATION_SYNC_KNOWLEDGE,
        this.info!.plugin_name,
      )
      .then((res: NormalResponse) => {
        if (!res.ok) throw new Error(`Response is not ok: ${res}`);
        this.info!.is_active = true;
        this.loadIntegrationInfo();
      });
  };
}
