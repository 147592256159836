export interface TagType {
  id?: number;
  name: TagTypeEnum | string;
  description?: string;
  color: string;
  unique: boolean;
  user_selectable: boolean;
  is_active?: boolean;
  is_system?: boolean;
  can_add_tags?: boolean;
}

export enum TagTypeEnum {
  PRIORITY = "priority",
  STATUS = "status",
  FEEDBACK = "feedback",
  AA = "automated-agent",
  AUTOMATION = "automation",
  SOURCE = "created-by",
  TEAM = "team",
  NEED = "need",
  PLUGIN = "plugin",
}

export interface EditTagType {
  name?: string;
  color: string;
  unique?: boolean;
  is_active?: boolean;
  user_selectable?: boolean;
  is_system?: boolean;
}
