import * as moment from "moment-timezone";

enum DurationUnits {
  DAYS = "d",
  HOURS = "h",
  MINUTES = "m",
}

interface DurationFromSla {
  value: number;
  unit: DurationUnits;
}

export const getDurationFromSLA = (duration: string): DurationFromSla => {
  const momentObj = moment.duration(duration);

  const days = momentObj.days();
  if (days > 0) {
    return {
      value: days,
      unit: DurationUnits.DAYS,
    };
  }

  const hours = momentObj.hours();
  if (hours > 0) {
    return {
      value: hours,
      unit: DurationUnits.HOURS,
    };
  }

  return {
    value: momentObj.minutes(),
    unit: DurationUnits.MINUTES,
  };
};

export const getDurationString = (value: number, unit: string): string => {
  const duration = moment.duration(value, unit as moment.DurationInputArg2);
  return duration.asSeconds().toString();
};
