import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { getFormErrors, setFormErrors } from "src/app/shared/utils/common";
import { Location } from "@angular/common";
import { WorkflowService } from "src/app/shared/services/workflow/workflow.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { WorkflowCategory } from "src/app/shared/models/workflow";

@Component({
  selector: "app-workflow-category-create",
  templateUrl: "./workflow-category-create.component.html",
  styleUrls: ["./workflow-category-create.component.scss"],
})
export class WorkflowCategoryCreateComponent implements OnInit {
  categoryForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [
      Validators.required,
      Validators.maxLength(255),
    ]),
    description: new UntypedFormControl("", [
      Validators.required,
      Validators.maxLength(10000),
    ]),
  });
  isLoading: boolean = true;
  // Edit workflow category
  workflowCategoryToEdit: WorkflowCategory | undefined = undefined;

  constructor(
    private location: Location,
    private router: Router,
    private workflowService: WorkflowService,
    private spinner: NgxSpinnerService,
  ) {
    this.workflowCategoryToEdit =
      this.router.getCurrentNavigation()?.extras.state?.workflow_category;
  }

  ngOnInit(): void {
    this.isLoading = false;
    if (this.workflowCategoryToEdit) {
      this.editWorkflowCategoryInitializeData();
    }
  }

  editWorkflowCategoryInitializeData = () => {
    if (!this.workflowCategoryToEdit) throw new Error("Shouldn't be here");

    this.categoryForm.controls.name.setValue(this.workflowCategoryToEdit.name);
    this.categoryForm.controls.description.setValue(
      this.workflowCategoryToEdit.description,
    );
  };

  getErrorMessage(field: string | null): string {
    return getFormErrors(field, this.categoryForm);
  }

  onCancel(): void {
    this.location.back();
  }

  get title() {
    return this.workflowCategoryToEdit
      ? "Update a Category"
      : "Create a Category";
  }

  onSave(): void {
    if (this.categoryForm.invalid) return;
    this.spinner.show();

    // Edit mode
    if (this.workflowCategoryToEdit) {
      this.workflowCategoryToEdit.name = this.categoryForm.controls.name.value;
      this.workflowCategoryToEdit.description =
        this.categoryForm.controls.description.value;

      this.workflowService
        .updateWorkflowCategory(this.workflowCategoryToEdit)
        .then(() => {
          // TODO: Show success message
          this.router.navigateByUrl("workflows");
        })
        .catch(err => {
          setFormErrors(err.error, this.categoryForm);
        })
        .finally(() => {
          this.isLoading = false;
          this.spinner.hide();
        });
    } else {
      // Create mode
      this.workflowService
        .createWorkflowCategory({
          name: this.categoryForm.controls.name.value,
          description: this.categoryForm.controls.description.value,
        })
        .then(() => {
          // TODO: Show success message
          this.router.navigateByUrl("workflows");
        })
        .catch(err => {
          setFormErrors(err.error, this.categoryForm);
        })
        .finally(() => {
          this.isLoading = false;
          this.spinner.hide();
        });
    }
  }
}
