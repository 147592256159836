<!-- Note that the animation has to be bound into the *ngFor in order to 'listen' to ::leave ::enter events. -->
<div
  *ngFor="let message of messages"
  [@inOutAnimation]="'in'"
  class="container"
>
  <div class="message-container">
    <img class="type-image" src="assets/images/info-red.svg" />
    <div class="rows">
      <div class="message">{{ message.title }}</div>
      <div *ngIf="message.description" class="message description">
        {{ message.description }}
      </div>
    </div>
    <div class="actions">
      <img
        *ngIf="message.closable"
        class="close"
        (click)="closeBtn(message.id!)"
        src="assets/images/close-btn.svg"
      />
      <div *ngIf="message.actions">
        <div *ngFor="let action of message.actions" class="buttons">
          <span (click)="action.callback()">
            <div class="button">{{ action.name }}</div>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
