<div>
  <div class="container">
    <div class="content">
      <img
        src="assets/images/close-btn.svg"
        class="pointer close-img"
        alt="close button"
        (click)="closeModal()"
      />
      <div class="title">Select all integration needed for this workflow.</div>

      <div
        class="box"
        *ngFor="let wIntegration of filteredIntegrations; let index = index"
      >
        <div class="integration">
          <div class="item">
            <mat-checkbox
              color="primary"
              (change)="onCheckboxSelect($event, wIntegration.integration)"
            >
            </mat-checkbox>
            <img
              [src]="wIntegration.integration.icon_enabled"
              alt="integration icon"
              *ngIf="wIntegration.integration.icon_enabled"
            />
            <span *ngIf="!nonTitleIntegration(wIntegration)">{{
              wIntegration.integration.name
            }}</span>
            <img
              (click)="togglePanelState(index)"
              class="right pointer"
              *ngIf="getPanelOpenState(index)"
              src="assets/images/arrow-up.svg"
            />
            <img
              (click)="togglePanelState(index)"
              class="right pointer"
              *ngIf="!getPanelOpenState(index)"
              src="assets/images/arrow-down.svg"
            />
          </div>
        </div>
        <div class="inner-content" [class.active]="getPanelOpenState(index)">
          <div
            class="content-actions"
            *ngIf="wIntegration.workers.length !== 0"
          >
            <span *ngFor="let worker of wIntegration.workers">{{
              worker.name
            }}</span>
          </div>
          <div
            class="content-actions"
            *ngIf="wIntegration.workers.length === 0"
          >
            <span>There are no available actions</span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <button mat-raised-button color="primary" (click)="onNext()">Next</button>
    </div>
  </div>
</div>
