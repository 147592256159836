import { Component, HostListener, Input, OnInit } from "@angular/core";
import { MetricsResolution } from "src/app/shared/models/metrics";
import { ChartType } from "angular-google-charts";
import { STORAGE_KEYS } from "src/app/shared/constants/localeStorageKeys";

interface SankeyChartInterface {
  sankey: {
    node: {
      width: number;
      colors: string[] | undefined;
      label: {
        fontSize: number;
      };
    };
    link: {
      colorMode: string;
      colors: string[];
      color: {
        // stroke: string;
        strokeWidth: number;
        // fill: string;
        // fillOpacity: number;
      };
    };
  };
}

const SANKEY_OPTIONS = {
  sankey: {
    node: {
      width: 5,
      colors: undefined,
      label: {
        fontSize: 14,
      },
    },
    link: {
      colorMode: "gradient",
      colors: ["black"],
      color: {
        // stroke: "white",
        strokeWidth: 0.5,
        // fill: "#d799ae",
        // fillOpacity: 0.1,
      },
    },
  },
};

const SIDEBAR_WIDTH: number = 240;
const PAGE_MARGINS: number = 35;

@Component({
  selector: "app-sankey-graph",
  templateUrl: "./sankey-graph.component.html",
  styleUrls: ["./sankey-graph.component.scss"],
})
export class SankeyGraphComponent implements OnInit {
  @Input() data: MetricsResolution = {} as MetricsResolution;

  chartLoadedOnce: boolean = false;
  title: string = "";
  type: ChartType = ChartType.Sankey;
  graphData: [string, string, number][] = [];
  columnNames = ["From", "To", "Weight"];
  options: SankeyChartInterface = SANKEY_OPTIONS;
  width: any = 0;
  height: any = 350;
  dynamicResize: boolean = true;
  hasData: boolean = false;

  constructor() {
    this.width = this.calcInnerWidth();
  }

  ngOnInit(): void {
    this.graphData = this.drawGraph(this.data);
    if (this.graphData && this.graphData.length !== 0) this.hasData = true;
    else this.hasData = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChartReady(event: any): void {
    if (!this.chartLoadedOnce) this.chartLoadedOnce = true;
  }

  calcInnerWidth = (): number => {
    // Calculates the inner width for a responsive graph.
    if (localStorage.getItem(STORAGE_KEYS.SIDEBAR) == "close")
      return window.innerWidth - PAGE_MARGINS;
    else return window.innerWidth - SIDEBAR_WIDTH - PAGE_MARGINS;
  };

  @HostListener("window:resize", ["$event"])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onWindowResize(event: any) {
    this.width = this.calcInnerWidth();
  }

  drawGraph = (data: MetricsResolution): [string, string, number][] => {
    // Graph does not allow zero values.
    let graphData: [string, string, number][] = [];
    this.options.sankey.node.colors = [];
    this.options.sankey.link.colors = ["#A9D4BA"];

    // NOTE:This does not work as the sdk expect an array!
    // this.options.sankey.link.colors = ["white"];

    if (data.total_manual_tickets || data.aa_offered || data.no_aa_kb_offered) {
      this.options.sankey.node.colors.push("#5CC285");
      this.options.sankey.link.colors.push("#E2E6F4");
    }
    // Requests

    if (data.aa_offered > 0) {
      graphData.push(["Conversations", "Automation Offered", data.aa_offered]);
      this.options.sankey.node.colors.push("#4569F7");
      this.options.sankey.link.colors.push("#E2E6F4");
    }

    if (data.no_aa_kb_offered > 0) {
      graphData.push(["Conversations", "KB Offered", data.no_aa_kb_offered]);
      this.options.sankey.node.colors.push("#4569F7");
      this.options.sankey.link.colors.push("#E2E6F4");
    }

    // Automation
    if (data.aa_declined_opened_ticket > 0) {
      graphData.push([
        "Automation Offered",
        "Ticket Created",
        data.aa_declined_opened_ticket,
      ]);
      this.options.sankey.node.colors.push("#4569F7");
      this.options.sankey.link.colors.push("#E2E6F4");
    }
    if (data.total_abandoned > 0) {
      graphData.push(["Automation Offered", "Abandoned", data.total_abandoned]);
      this.options.sankey.node.colors.push("#4569F7");
      this.options.sankey.link.colors.push("#E2E6F4");
    }

    if (data.aa_accepted > 0) {
      graphData.push(["Automation Offered", "Auto-Resolved", data.aa_accepted]);
      this.options.sankey.node.colors.push("#4569F7");
      this.options.sankey.link.colors.push("#E2E6F4");
    }

    if (data.aa_declined_kb_offered > 0) {
      graphData.push([
        "Automation Offered",
        "KB Offered",
        data.aa_declined_kb_offered,
      ]);
      this.options.sankey.node.colors.push("#4569F7");
      this.options.sankey.link.colors.push("#E2E6F4");
    }

    // Kb
    if (data.kb_declined_opened_ticket > 0) {
      graphData.push([
        "KB Offered",
        "Ticket Created",
        data.kb_declined_opened_ticket,
      ]);
      this.options.sankey.node.colors.push("#4569F7");
      this.options.sankey.link.colors.push("#E2E6F4");
    }
    if (data.total_deflected > 0) {
      graphData.push(["KB Offered", "Diverted", data.total_deflected]);
      this.options.sankey.node.colors.push("#4569F7");
      this.options.sankey.link.colors.push("#E2E6F4");
    }

    return graphData;
  };
}
