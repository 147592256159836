<div *ngIf="active">
  <div class="title">Workflow Schedule</div>
  <div class="container">
    <div class="kickoff-container">
      <div class="inner">
        <span class="title">Kick off</span>
        <span class="sub-title"
          >Enable workflow to start at a specific date</span
        >
        <form class="gaspar-form" [formGroup]="kickOffForm">
          <mat-dialog-content>
            <mat-form-field class="w-50" appearance="outline">
              <mat-select formControlName="option">
                <mat-option *ngFor="let option of options" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="kickOffForm.controls.option.invalid">
                {{ getErrorMessage("option") }}
              </mat-error>
            </mat-form-field>
          </mat-dialog-content>
        </form>
      </div>
    </div>
    <div class="actions">
      <a mat-flat-button class="pointer" (click)="onCancel()"> Cancel </a>
      <div class="right">
        <a mat-flat-button (click)="onBack()" class="pointer"> Back </a>
        <button mat-raised-button color="primary" (click)="onKickOffSave()">
          Next
        </button>
      </div>
    </div>
  </div>
</div>
