<div *ngIf="!isLoading">
  <!-- Title related -->
  <div class="title-bar">
    <span i18n="workflows:workflow title" class="gaspar-title">Workflows</span>
    <button mat-raised-button color="primary">
      <div (click)="onCreateWorkflowModal()">
        <img
          src="assets/images/workflow/plus.svg"
          alt="add"
          i18n="workflows:workflow create button"
        />
        Create
      </div>
    </button>
  </div>

  <div class="tabs-container">
    <button
      mat-flat-button
      i18n="workflows:active tab"
      [class.selected]="onSelectedTagChange('active')"
      (click)="onChangeTag('active')"
      class="tab-btn"
    >
      Active
    </button>
    <div class="grey-dot"></div>
    <button
      mat-flat-button
      [class.selected]="onSelectedTagChange('archived')"
      (click)="onChangeTag('archived')"
      i18n="workflows:archived tab"
      class="tab-btn"
    >
      Archived
    </button>
  </div>
  <div class="w-content-container">
    <div class="title">
      <span
        i18n="workflows:all workflow categories"
        class="pointer"
        (click)="navigateToWorkflows()"
      >
        All Categories
      </span>
      <img src="assets/images/polygon.svg" alt="polygon" />
      <span> {{ category!.name }} </span>
    </div>
    <div class="w-content">
      <div *ngFor="let workflow of workflows">
        <div class="box item">
          <div class="wrapper">
            <div class="header">
              <span class="created_at" i18n="workflows: workflow created"
                >Created: {{ workflow.created_at | date }}</span
              >
              <div class="dots" [matMenuTriggerFor]="menu">
                <img src="assets/images/dots.svg" />
                <mat-menu #menu="matMenu" backdropClass="gaspar-menu">
                  <button
                    mat-menu-item
                    (click)="onWorkflowEditClicked(workflow)"
                    *ngIf="workflow.is_active"
                  >
                    <div class="item">Edit</div>
                  </button>
                  <button
                    mat-menu-item
                    (click)="onWorkflowDuplicateClicked(workflow)"
                    *ngIf="workflow.is_active"
                  >
                    <div class="item">Duplicate</div>
                  </button>
                  <button
                    mat-menu-item
                    (click)="onWorkflowInfoClicked(workflow)"
                  >
                    <div class="item">Info</div>
                    <app-modal [id]="workflowModalKey(workflow)">
                      <app-info-modal
                        [id]="workflowModalKey(workflow)"
                        header="Workflow Info"
                        [title]="workflow.title"
                        [description]="workflow.description"
                        [isWorkflow]="true"
                        [isActive]="workflow!.is_active"
                        [instancePk]="workflow.id"
                      ></app-info-modal>
                    </app-modal>
                  </button>
                  <button
                    mat-menu-item
                    (click)="onWorkflowToggleArchiveClicked(workflow)"
                  >
                    <div class="item">
                      {{ workflow.is_active ? "Archive" : "Unarchive" }}
                    </div>
                  </button>
                </mat-menu>
              </div>
            </div>

            <span class="name">{{ workflow.title | trimText: 50 }}</span>
            <span class="description word-break">
              {{ workflow.description | trimText: 80 }}</span
            >
            <div class="button">
              <button
                mat-raised-button
                color="primary"
                i18n="workflows: run workflow btn"
                (click)="runWorkflowModal(workflow)"
                *ngIf="workflow.is_active"
              >
                Run
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="box create centered" (click)="navigateToWorkflowCreation()">
        <div class="image-alligned" i18n="workflows:workflow create">
          <img src="assets/images/workflow/workflow.svg" />
          Create a Workflow
        </div>
      </div>
    </div>
  </div>
</div>
