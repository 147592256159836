import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { URLS } from "../../constants/urls";
import { EditTag, Tag } from "../../models/tag";
import { EditTagType, TagType } from "../../models/tagType";
import { ServiceModule } from "../service.module";

@Injectable({
  providedIn: "root",
})
export class TagService extends ServiceModule {
  private _tags: Tag[] = [];
  tagTypes: TagType[] = [];
  tagTypesSubject: BehaviorSubject<TagType[]> = new BehaviorSubject<TagType[]>(
    [],
  );
  tagSubject: BehaviorSubject<Tag[]> = new BehaviorSubject<Tag[]>([]);
  createTagTypeSubject: Subject<TagType> = new Subject();
  createTagSubject: Subject<Tag> = new Subject();
  editTagTypeSubject: Subject<any> = new Subject();

  get tags() {
    return this._tags || [];
  }

  constructor(private http: HttpClient) {
    super();
  }

  getTags() {
    return this.http
      .get<Tag[]>(URLS.TAG)
      .toPromise()
      .then((data: Tag[]) => {
        this._tags = data;
        this.tagSubject.next(data);
        return data;
      });
  }

  getTagTypes() {
    return this.http
      .get<TagType[]>(URLS.TAG_TYPE)
      .toPromise()
      .then((data: TagType[]) => {
        this.tagTypes = data;
        this.tagTypesSubject.next(data);
        return data;
      });
  }

  createTagType(tagType: TagType) {
    return this.http
      .post<TagType>(URLS.TAG_TYPE, tagType)
      .toPromise()
      .then((result: TagType) => {
        this.tagTypes.push(result);
        this.createTagTypeSubject.next(result);
      });
  }

  editTagType(name: string, tagType: EditTagType) {
    return this.http
      .patch<TagType>(`${URLS.TAG_TYPE}${name}/`, tagType)
      .toPromise()
      .then((result: TagType) => {
        this.editTagTypeSubject.next({
          name,
          tagType: result,
        });

        const itemIndex = this.tagTypes.findIndex(item => item.name === name);
        this.tagTypes[itemIndex] = result;

        this.tags
          .filter(tag => tag.type.name === name)
          .forEach(tag => {
            tag.type = result;
          });
      });
  }

  createTag(tag: Tag) {
    return this.http
      .post<Tag>(URLS.TAG, tag)
      .toPromise()
      .then((result: Tag) => {
        this.tags.push(result);
      });
  }

  editTag(slug: string, tag: EditTag) {
    return this.http
      .patch<Tag>(`${URLS.TAG}${slug}/`, tag)
      .toPromise()
      .then((result: Tag) => {
        const index = this.tags.findIndex((item: Tag) => item.id === result.id);
        if (index > -1) {
          this.tags[index] = result;
        }
      });
  }
}
