<div class="bar">
  <div class="container">
    <div class="head bold">
      <div>{{ header }}</div>
      <img
        src="assets/images/close-btn.svg"
        class="pointer"
        (click)="closeModal()"
        alt="close button"
      />
    </div>
    <div class="content">
      <p class="name bold">{{ title }}</p>
      <p class="description bold">Description:</p>
      <span class="description">{{ nullableDescription }}</span>
      <div class="inner-table">
        <div class="row" *ngIf="!isWorkflow">
          <p class="bold">Active workflows</p>
          <span>{{ activeWorkflows }}</span>
        </div>
        <div class="row" *ngIf="!isWorkflow">
          <p class="bold">Archived workflows</p>
          <span> {{ archivedWorkflows }} </span>
        </div>
        <div class="row" *ngIf="created_at">
          <p class="bold">Created</p>
          <span>{{ created_at | date }}</span>
        </div>
      </div>
    </div>
    <div class="actions">
      <button
        mat-raised-button
        color="primary"
        [disabled]="!isActive"
        (click)="onEditClicked()"
      >
        Edit
      </button>
      <button mat-raised-button (click)="onArchiveOrUnarchive()">
        {{ archiveOrUnarchiveText }}
      </button>
    </div>
  </div>
</div>
