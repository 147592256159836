import { Component, OnInit } from "@angular/core";
import { SlideInOutAnimation } from "../../animations/slide";
import { MessagesService } from "../../services/messages/messages.service";

export enum MessageType {
  SUCCESS = "success",
  INFO = "info",
  ERROR = "error",
  WARNING = "warning",
}

export interface MessageAction {
  name: string;
  callback(): any;
}
export interface Message {
  id?: number;
  title: string;
  description?: string;
  type?: MessageType;
  time?: number; // This is in MS. E.g. 5000 => 5seconds.
  closable?: boolean;
  actions?: MessageAction[];
}

@Component({
  selector: "app-messages",
  templateUrl: "./messages.component.html",
  styleUrls: ["./messages.component.scss"],
  animations: [SlideInOutAnimation],
})
export class MessagesComponent implements OnInit {
  constructor(private messageService: MessagesService) {}

  ngOnInit(): void {}

  get messages() {
    return this.messageService.messages;
  }

  closeBtn = (message_id: number) => {
    this.messageService.remove(message_id);
  };
}
