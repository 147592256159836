<div *ngIf="!isLoading">
  <app-sidebar [isSidebarAvailable]="isAuthenticated">
    <router-outlet>
      <app-messages></app-messages>
      <ngx-spinner
        bdColor="rgba(0, 0, 0, 0)"
        size="medium"
        color="#4569f7"
        type="ball-spin"
        [fullScreen]="true"
      ></ngx-spinner>
    </router-outlet>
  </app-sidebar>
</div>
