import { Component, OnInit } from "@angular/core";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { ActivatedRoute, Router } from "@angular/router";
import { WorkflowService } from "src/app/shared/services/workflow/workflow.service";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { WorkflowCreateModalComponent } from "../workflow-create-modal/workflow-create-modal.component";
import { Workflow, WorkflowCategory } from "src/app/shared/models/workflow";
import { ModalService } from "src/app/shared/services/modal/modal.service";
import { RunWorkflowModalComponent } from "../components/run-workflow-modal/run-workflow-modal.component";

@Component({
  selector: "app-workflows-per-category",
  templateUrl: "./workflows-per-category.component.html",
  styleUrls: ["./workflows-per-category.component.scss"],
})
export class WorkflowsPerCategoryComponent implements OnInit {
  selectedTab: string = "active"; // ["active", "archived"]
  isLoading: boolean = false;
  ellipsisIcon = faEllipsisV;
  category: WorkflowCategory | undefined = undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private workflowService: WorkflowService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.spinner.show();

    this.category = this.workflowService.workflowCategories.find(
      (item: WorkflowCategory) => {
        return item.id!.toString() === this.route.snapshot.params["id"];
      },
    );
    if (!this.category) throw new Error("Couldn't find the requested category");

    this.workflowService
      .getWorkflows()
      .catch(() => {
        // TODO: Error handling
      })
      .finally(() => {
        this.spinner.hide();
        this.isLoading = false;
      });
  }

  get workflows() {
    return this.workflowService.workflows.filter((workflow: Workflow) => {
      return (
        (this.activeTabSelected ? workflow.is_active : !workflow.is_active) &&
        workflow.category === this.category!.id
      );
    });
  }

  get activeTabSelected() {
    return this.selectedTab === "active";
  }

  onSelectedTagChange = (value: string): boolean => {
    return this.selectedTab === value;
  };

  onChangeTag = (value: string): void => {
    this.selectedTab = value;
  };

  // Workflow Actions
  onWorkflowEditClicked = (workflow: Workflow) => {
    this.router.navigateByUrl("workflow/update", {
      state: { workflow: workflow },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onWorkflowDuplicateClicked = (workflow: Workflow) => {
    this.spinner.show();
    this.isLoading = true;

    this.workflowService
      .createWorkflow(workflow)
      .then((workflow: Workflow) => {
        this.router.navigateByUrl("workflow/update", {
          state: { workflow: workflow, step: 5 },
        });
      })
      .catch(() => {
        // TODO: error handling.
      })
      .finally(() => {
        this.spinner.hide();
        this.isLoading = false;
      });
  };

  workflowModalKey = (workflow: Workflow): string => {
    return `workflow_${workflow.id!.toString()}`;
  };

  onWorkflowInfoClicked = (workflow: Workflow) => {
    this.modalService.open(this.workflowModalKey(workflow));
  };

  onWorkflowToggleArchiveClicked = (workflow: Workflow) => {
    workflow.is_active = !workflow.is_active;
    this.workflowService
      .updateWorkflow(workflow)
      .then(() => {
        // TODO: Success message
      })
      .catch(() => {
        // TODO: error message
      });
  };

  navigateToWorkflowCreation() {
    this.router.navigateByUrl("workflow/create");
  }

  navigateToWorkflows() {
    this.router.navigateByUrl("workflows");
  }

  runWorkflowModal = (workflow: Workflow): void => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "gaspar-modal";
    dialogConfig.data = {
      workflow: workflow,
    };
    this.dialog.open(RunWorkflowModalComponent, dialogConfig);
  };

  onCreateWorkflowModal = (): void => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "gaspar-modal-large";
    this.dialog.open(WorkflowCreateModalComponent, dialogConfig);
  };
}
