<mat-dialog-content>
  <mat-dialog-header>
    <div i18n="start-workflow-modal: title" class="title">
      {{ prettifiedTitle }}
    </div>
    <hr />
  </mat-dialog-header>
  <div class="modal-content">
    <app-form
      *ngIf="form?.definition"
      [definition]="form!.definition"
      (submitEvent)="onSubmit($event)"
      (cancelEvent)="onCancel()"
      [isSubmitLoading]="isSubmitBtnLoading"
    >
    </app-form>
  </div>
</mat-dialog-content>
