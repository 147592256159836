import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Integration } from "src/app/shared/models/integration";
import { WorkflowIntegration } from "src/app/shared/models/workflow";
import { ModalService } from "src/app/shared/services/modal/modal.service";
import { Worker } from "src/app/shared/models/worker";
import { Location } from "@angular/common";
import { UntypedFormGroup } from "@angular/forms";
import { MatSelect } from "@angular/material/select";
import { MatOption } from "@angular/material/core";

@Component({
  selector: "app-workflow-integrations-step",
  templateUrl: "./workflow-integrations-step.component.html",
  styleUrls: ["./workflow-integrations-step.component.scss"],
})
export class WorkflowIntegrationsStepComponent implements OnInit {
  @Input() active: boolean = false;
  @Input() integrations: WorkflowIntegration[] = [];
  @Input() selectedWorkflowIntegrations: WorkflowIntegration[] = [];
  @Output() submitEvent = new EventEmitter();
  @Output() backEvent = new EventEmitter();

  @ViewChild("matSelectRef") matSelectRef: MatSelect = {} as MatSelect;

  forms: UntypedFormGroup[] = [];
  // Generic error pre-submitting the form.
  errors: string[] = [];

  constructor(private modalService: ModalService, private location: Location) {}

  ngOnInit(): void {}

  removeWorkerFromIntegration = (integration: Integration, worker: Worker) => {
    for (let int of this.selectedWorkflowIntegrations) {
      // find the stored integration.
      if (int.integration.code === integration.code) {
        // Find the worker and filter it out.
        int.workers = int.workers.filter(w => {
          return w !== worker;
        });
      }
    }
  };

  openIntegrationsModal = (key: string) => {
    this.modalService.open(key);
  };

  onIntegrationsSelect = (integrations: Integration[]) => {
    // Make sure the integration hasn't been added yet.
    for (let integration of integrations) {
      let exists = this.selectedWorkflowIntegrations.some(
        (item: WorkflowIntegration) => {
          return item.integration.code === integration.code;
        },
      );
      if (exists) continue;

      this.selectedWorkflowIntegrations.push({
        integration: integration,
        is_active: true,
        workers: [],
      });
    }
  };

  getWorkersForIntegration = (integration: Integration) => {
    let wantedIntegration = this.integrations.find(
      (workflowIntegration: WorkflowIntegration) => {
        return workflowIntegration.integration.code == integration.code;
      },
    );
    return wantedIntegration!.workers;
  };

  getWorkerFormDefinition = (worker: Worker, integration: Integration) => {
    let workers = this.getWorkersForIntegration(integration);

    let selectedWorker = workers.find((w: Worker) => {
      return w.name === worker.name;
    });

    return selectedWorker!.definition;
  };

  clearSelections = () => {
    this.matSelectRef.options.forEach((data: MatOption) => data.deselect());
  };

  onSelectionChange(event: any, wIntegration: WorkflowIntegration) {
    // Find the selected worker.
    // NOTE: Mind that if we don't use the Object.assign method here,
    // we will be using the exact same worker in all integrations which will cause
    // mutabillity problems.

    // Avoid triggering twice the selection event on deselection.
    if (event.value === undefined) return;

    let selectedWorker: Worker | undefined = Object.assign(
      {},
      this.getWorkersForIntegration(wIntegration.integration).find(
        (worker: Worker) => {
          return worker.name == event.value;
        },
      ),
    );

    for (let workflowIntegration of this.selectedWorkflowIntegrations) {
      let integration: Integration = workflowIntegration.integration;

      if (integration.code === wIntegration.integration.code) {
        workflowIntegration.workers.push(selectedWorker!);
      }
    }
    this.clearSelections();
  }

  addFormToState = (form: UntypedFormGroup): void => {
    this.forms.push(form);
  };

  areFormsValid = (): boolean => {
    // Validate all forms in once.
    let isValid: boolean = true;

    for (let form of this.forms) {
      if (form.invalid) {
        for (let i in form.controls) {
          form.controls[i].markAsDirty();
        }
        isValid = false;
      }
    }
    return isValid;
  };

  genericErrors = (): boolean => {
    // Show in the UI any error related to the integration and
    // return a boolean if an error has been found.
    this.errors = [];
    let integrationsAreValid = false;
    if (this.selectedWorkflowIntegrations.length === 0) {
      this.errors.push(
        "Please, select at least one integration in order to proceed.",
      );
    }
    for (let wIntegration of this.selectedWorkflowIntegrations) {
      if (wIntegration.workers.length > 0 && !integrationsAreValid) {
        integrationsAreValid = true;
      }
    }

    if (!integrationsAreValid) {
      this.errors.push("Please, select at least one worker.");
      return false;
    } else {
      // Needed in order to clean the error for the next step.
      this.errors = [];
      return true;
    }
  };

  onWorkflowSave = () => {
    if (!this.genericErrors()) return;
    if (!this.areFormsValid()) return;

    this.submitEvent.emit(this.selectedWorkflowIntegrations);
  };

  onCancel = () => {
    this.location.back();
  };

  onBack = () => {
    this.backEvent.emit();
  };
}
