import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "trimText",
})
export class TrimTextPipe implements PipeTransform {
  transform(value: string | null | undefined, length: number = 100): unknown {
    if (!value) {
      return "";
    } else {
      let parsed_text = value.substring(0, length);
      if (value.length > length) parsed_text += "...";
      return parsed_text;
    }
  }
}
