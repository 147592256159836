import { Component, Inject, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MdePopoverTrigger } from "@niewicz/mde-popover";
import { Tag } from "src/app/shared/models/tag";
import { TagTypeEnum } from "src/app/shared/models/tagType";
import { TagService } from "src/app/shared/services/tag/tag.service";
import { UserService } from "src/app/shared/services/user/user.service";
import { getFormErrors, setFormErrors } from "src/app/shared/utils/common";

@Component({
  selector: "app-edit-expertise-modal",
  templateUrl: "./edit-expertise-modal.component.html",
  styleUrls: ["./edit-expertise-modal.component.scss"],
})
export class EditExpertiseModalComponent {
  @ViewChild(MdePopoverTrigger, { static: true }) trigger!: MdePopoverTrigger;
  _tags: Tag[] = [];
  expertiseForm = new UntypedFormGroup({
    query: new UntypedFormControl(""),
  });
  selectedTags: Tag[];
  isLoading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private dialogRef: MatDialogRef<EditExpertiseModalComponent>,
    private userService: UserService,
    private tagService: TagService,
  ) {
    this.selectedTags = [...this.data.user.tags];
  }

  get data() {
    // the data passed in the modal
    return this._data;
  }

  get tags() {
    return this.tagService.tags
      .filter((tag: Tag) => tag.is_active && tag.type.is_active)
      .filter(
        (tag: Tag) =>
          tag.type.name != TagTypeEnum.AA &&
          tag.type.name != TagTypeEnum.AUTOMATION &&
          tag.type.name != TagTypeEnum.FEEDBACK &&
          tag.type.name != TagTypeEnum.SOURCE &&
          tag.type.name != TagTypeEnum.NEED &&
          tag.type.name != TagTypeEnum.STATUS,
      )
      .filter((tag: Tag) => !tag.closes) // Not a closing Tag
      .filter((tag: Tag) =>
        tag.name!.includes(this.expertiseForm.controls.query.value),
      ); // Based on user's query
  }

  isAssigned = (tag: Tag) => {
    // Checks if the given tags is assigned to the given user
    return !!this.selectedTags.find(item => item.id === tag.id);
  };

  onExpertiseTagSelected(tag: Tag): void {
    // Add a tag as an expertise tag in the modal
    if (this.isAssigned(tag)) {
      return;
    }
    this.selectedTags.push(tag);
  }

  searchChanged = () => {
    this.trigger.openPopover();
  };

  onExpertiseTagRemove(tag: Tag): void {
    // Remove a tag from the expertise modal
    this.selectedTags = this.selectedTags.filter(
      (item: Tag) => item.id !== tag.id,
    );
  }

  getErrorMessage(): string {
    return getFormErrors(null, this.expertiseForm);
  }

  onCancel(): void {
    // Close the modal / cancel any action
    this.dialogRef.close(true);
  }

  onSave(): void {
    // Save the selected expertise tags
    this.isLoading = true;
    let tag_ids: any = this.selectedTags.map((tag: Tag) => tag.id);
    this.userService
      .updateUserTags(this.data.user.id, tag_ids)
      .then(() => {
        this.dialogRef.close();
      })
      .catch(err => {
        setFormErrors(err.error, this.expertiseForm);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
