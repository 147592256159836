import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { SidebarStatusEnum } from "src/app/shared/components/sidebar/sidebar.component";
import { GASPAR } from "src/app/shared/constants/gaspar";
import { STORAGE_KEYS } from "src/app/shared/constants/localeStorageKeys";
import {
  AuthenticationDetail,
  TokenExchange,
} from "src/app/shared/models/authentication";
import { Form, FormFieldType } from "src/app/shared/models/forms";
import { AuthenticationService } from "src/app/shared/services/authentication/authentication.service";
import { TagService } from "src/app/shared/services/tag/tag.service";
import { UserService } from "src/app/shared/services/user/user.service";
import { setFormErrors } from "src/app/shared/utils/common";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  authDetail: AuthenticationDetail = {} as AuthenticationDetail;
  error: any;
  isLoading: boolean = true;

  loginFormWithEmail: Form = {
    name: "Login Form",
    is_active: true,
    is_system: true,
    definition: [
      {
        name: "email",
        required: true,
        type: FormFieldType.STR,
        display_name: "Email",
      },
      {
        name: "password",
        required: true,
        type: FormFieldType.PASSWORD,
        display_name: "Password",
      },
    ],
    values: {},
  };

  constructor(
    private auth: AuthenticationService,
    private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private userService: UserService,
    private tagService: TagService,
  ) {}

  onSubmit = (form: any) => {
    // This method is called when the form is submitted for customers that use email and password
    this.authService
      .authWithEmail(form.controls.email.value, form.controls.password.value)
      .then(async (result: TokenExchange) => {
        if (!result.token)
          throw new Error("Didn't receive a token, can't proceed sorry");

        Promise.all([
          this.userService.getSelf(),
          this.tagService.getTags(),
          this.tagService.getTagTypes(),
        ])
          .then(() => {
            this.router.navigateByUrl(GASPAR.DEFAULT_SCREEN);
            this.spinner.hide();
            localStorage.setItem(STORAGE_KEYS.SIDEBAR, SidebarStatusEnum.OPEN);
          })
          .catch(() => {
            localStorage.removeItem(STORAGE_KEYS.TOKEN);
            this.router.navigateByUrl("");
          });
      })
      .catch(err => {
        setFormErrors(err.error, form);
      })
      .finally(() => {
        this.isLoading = false;
        this.spinner.hide();
      });
  };

  ngOnInit(): void {
    this.spinner.show();
    // Retrieve the enabled sso and its url.
    this.auth
      .getAuthDetails()
      .then((data: AuthenticationDetail) => {
        this.authDetail = data;
      })
      .catch(() => {
        this.error =
          "Couldn't retrieve authentication details, please try again later or contact with our administrator.";
      })
      .finally(() => {
        this.spinner.hide();
        this.isLoading = false;
      });
  }

  sso = () => {
    // Opens a new windor with the provided sso url
    if (!this.authDetail)
      throw new Error("There are no url provided for the sso");

    window.open(this.authDetail.url, "_self");
  };
}
