<label [attr.for]="inputId" class="mat-slide-toggle-label" #label>
  <div
    #toggleBar
    class="mat-slide-toggle-bar"
    [class.mat-slide-toggle-bar-no-side-margin]="
      !labelContent.textContent || !labelContent.textContent.trim()
    "
  >
    <mat-icon class="bar-icon disabled">{{ disabledIcon }}</mat-icon>
    <mat-icon class="bar-icon enabled">{{ enabledIcon }}</mat-icon>
    <!-- A hacky way to force all the default styling to be imported -->
    <mat-slide-toggle style="visibility: hidden"></mat-slide-toggle>
    <input
      #input
      class="mat-slide-toggle-input cdk-visually-hidden"
      type="checkbox"
      role="switch"
      [id]="inputId"
      [required]="required"
      [tabIndex]="tabIndex"
      [checked]="checked"
      [disabled]="disabled"
      [attr.name]="name"
      [attr.aria-checked]="checked.toString()"
      [attr.aria-label]="ariaLabel"
      [attr.aria-labelledby]="ariaLabelledby"
      (change)="_onChangeEvent($event)"
      (click)="_onInputClick($event)"
    />

    <div class="mat-slide-toggle-thumb-container" #thumbContainer>
      <div class="mat-slide-toggle-thumb">
        <mat-icon>{{ checked ? enabledIcon : disabledIcon }}</mat-icon>
      </div>
      <div
        class="mat-slide-toggle-ripple"
        mat-ripple
        [matRippleTrigger]="label"
        [matRippleDisabled]="disableRipple || disabled"
        [matRippleCentered]="true"
        [matRippleRadius]="20"
        [matRippleAnimation]="{ enterDuration: 150 }"
      >
        <div
          class="mat-ripple-element mat-slide-toggle-persistent-ripple"
        ></div>
      </div>
    </div>
  </div>

  <span
    class="gaspar-span mat-slide-toggle-content"
    #labelContent
    (cdkObserveContent)="_onLabelTextChange()"
  >
    <!-- Add an invisible span so JAWS can read the label -->
    <span style="display: none">&nbsp;</span>
    <ng-content></ng-content>
  </span>
</label>
