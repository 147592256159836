<div class="navbar">
  <div class="navbar-container">
    <button
      mat-button
      class="menu-button pc-hidden"
      (click)="onDrawerToggle()"
      *ngIf="isSidebarAvailable"
    >
      <mat-icon>menu</mat-icon>
    </button>
    <span i18n="navbar:gaspar title" class="navbar-title">
      {{ GASPAR.TITLE }}
    </span>
    <span class="gaspar-span instance-badge">{{ instanceName }}</span>
    <div class="user-badge-container" *ngIf="username">
      <span class="username">{{ username }}</span>
      <span
        class="user-badge pointer"
        [mdePopoverTriggerFor]="avatarPopover"
        mdePopoverTriggerOn="click"
        #popoverTrigger="mdePopoverTrigger"
        [mdePopoverBackdropCloseOnClick]="true"
      >
        {{ userAcronym }}
      </span>
      <mde-popover #avatarPopover="mdePopover" [mdePopoverCloseOnClick]="false">
        <div class="badge-popover">
          <button
            mat-raised-button
            class="logout-btn"
            color="primary"
            (click)="logout()"
            i18n="navbar:Logout button"
          >
            Logout
          </button>
        </div>
      </mde-popover>
    </div>
  </div>
</div>
