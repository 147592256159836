import { Component, Input, OnInit } from "@angular/core";
import { PolusQueryInterface } from "src/app/shared/models/metrics";

@Component({
  selector: "app-polus-query-table",
  templateUrl: "./polus-query-table.component.html",
  styleUrls: ["./polus-query-table.component.scss"],
})
export class PolusQueryTableComponent implements OnInit {
  @Input() data: PolusQueryInterface[] = [];
  displayedColumns: string[] = ["query", "user", "plugin_name"];

  // Paginator
  paginatedDataSource: PolusQueryInterface[] = [];
  pageIndex: number = 0;
  pageSize: number = 20;
  pageSizeOptions: number[] = [10, 20, 50, 100];

  constructor() {}

  ngOnInit(): void {
    this.getPaginatorData(undefined);
  }

  getPaginatorData = (event: any) => {
    if (event !== undefined) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    }
    const low = this.pageIndex * this.pageSize;
    const high = low + this.pageSize;

    this.paginatedDataSource = this.data.slice(low, high);
  };

  parseHintsToStr = (hints: object): string => {
    let content: string = "";
    for (const [key, value] of Object.entries(hints)) {
      content += `- ${key}: ${value}\n`;
    }
    return content ? content : "-";
  };
}
