import { Component, HostListener, Input } from "@angular/core";
import { GASPAR } from "../../constants/gaspar";
import { STORAGE_KEYS } from "../../constants/localeStorageKeys";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { UserService } from "../../services/user/user.service";
import { SidebarStatusEnum } from "../sidebar/sidebar.component";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent {
  @Input() drawer: any;
  @Input() isSidebarAvailable: boolean = false;

  GASPAR = GASPAR;
  username: string = "";
  resizeTimeout: any = null;

  constructor(
    private userService: UserService,
    private authService: AuthenticationService,
  ) {
    this.username = this.userService.user.name;
  }

  get userAcronym() {
    if (!this.username) return this.username;

    return this.username
      .split(" ")
      .map(item => item.charAt(0))
      .join("");
  }

  get instanceName() {
    return window.location.host.split(".")[0];
  }

  @HostListener("window:resize")
  onWindowResize(): void {
    // Triggers the on window resize event.
    // If the window get's resized to a high res display then we want the sidebar fixed.
    // So, in  case it's closed, open it.
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(
      (() => {
        if (window.innerWidth >= 1025 && window.innerWidth <= 1281) {
          if (this.isSidebarAvailable) {
            this.drawer.open();
            localStorage.setItem(STORAGE_KEYS.SIDEBAR, SidebarStatusEnum.OPEN);
          }
        }
      }).bind(this),
      100,
    );
  }

  onDrawerToggle = (): void => {
    //Toggle the sidebar and store the current value into the local storage
    this.drawer.toggle().then((state: string) => {
      localStorage.setItem(STORAGE_KEYS.SIDEBAR, state);
    });
  };

  logout = (): void => {
    // clear the local storage and force reload the window so we reset all the data.
    localStorage.removeItem(STORAGE_KEYS.SIDEBAR);
    this.authService.logout(true);
  };
}
