import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Tag } from "../../models/tag";
import { getDurationFromSLA } from "../../utils/sla/sla";

@Component({
  selector: "app-tag",
  templateUrl: "./tag.component.html",
  styleUrls: ["./tag.component.scss"],
})
export class TagComponent {
  @Input() tag!: Tag;
  @Input() disabled = false;
  @Input() showSlas = false;
  @Output() tagClick: EventEmitter<any> = new EventEmitter();

  constructor() {}

  onTagClick = (tag: Tag) => {
    this.tagClick.emit(tag);
  };

  get name() {
    let name = this.tag.name;
    if (this.tag.sla_resolve && this.showSlas) {
      const { value, unit } = getDurationFromSLA(this.tag.sla_resolve);
      name += ` (Resolve ${value}${unit})`;
    }
    if (this.tag.sla_assign && this.showSlas) {
      const { value, unit } = getDurationFromSLA(this.tag.sla_assign);
      name += ` (Respond ${value}${unit})`;
    }

    return name;
  }
}
