import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";

@Component({
  selector: "app-workflow-create-modal",
  templateUrl: "./workflow-create-modal.component.html",
  styleUrls: ["./workflow-create-modal.component.scss"],
})
export class WorkflowCreateModalComponent implements OnInit {
  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    public dialogRef: MatDialogRef<WorkflowCreateModalComponent>,
  ) {}

  ngOnInit(): void {}

  navigateToWorkflowCategoryCreation() {
    this.router.navigateByUrl("workflow/category/create");
    this.dialogRef.close();
  }

  navigateToWorkflowCreation() {
    this.router.navigateByUrl("workflow/create");
    this.dialogRef.close();
  }
}
