import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Workflow, WorkflowCategory } from "src/app/shared/models/workflow";
import { ModalService } from "src/app/shared/services/modal/modal.service";
import { WorkflowService } from "src/app/shared/services/workflow/workflow.service";

@Component({
  selector: "app-info-modal",
  templateUrl: "./info-modal.component.html",
  styleUrls: ["./info-modal.component.scss"],
})
export class InfoModalComponent implements OnInit {
  @Input() id: string = "0";
  @Input() header: string = "Information";
  @Input() title: string = "";
  @Input() description?: string | null | undefined = null;
  // If is not a workflow, it's a workflow category.
  @Input() isActive: boolean | undefined = false;
  @Input() isWorkflow: boolean = false;
  @Input() instancePk: number | undefined = undefined;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private workflowService: WorkflowService,
  ) {}

  ngOnInit(): void {}

  get nullableDescription(): string {
    return this.description ? this.description : "No available description";
  }

  get activeWorkflows(): number {
    let numOfWorkflows: number = 0;
    this.workflowService.workflows.filter((workflow: Workflow) => {
      if (
        workflow.category &&
        workflow.is_active &&
        workflow.category == this.instancePk
      ) {
        numOfWorkflows += 1;
      }
    });
    return numOfWorkflows;
  }

  get archivedWorkflows(): number {
    let numOfWorkflows: number = 0;
    this.workflowService.workflows.filter((workflow: Workflow) => {
      if (
        workflow.category &&
        !workflow.is_active &&
        workflow.category == this.instancePk
      ) {
        numOfWorkflows += 1;
      }
    });
    return numOfWorkflows;
  }

  get instance() {
    let instance;
    if (!this.isWorkflow)
      instance = this.workflowService.workflowCategories.find(
        (category: WorkflowCategory) => {
          return category.id === this.instancePk;
        },
      );
    else
      instance = this.workflowService.workflows.find((workflow: Workflow) => {
        return workflow.id === this.instancePk;
      });

    if (!instance)
      throw new Error("Not an existing workflow / workflow_category?");

    return instance;
  }

  get created_at() {
    return this.instance.created_at ? this.instance.created_at : "";
  }

  get archiveOrUnarchiveText() {
    return this.isActive ? "Archive" : "Unarchive";
  }

  onArchiveOrUnarchive = () => {
    let instance;
    if (this.isWorkflow) {
      instance = this.workflowService.workflows.find((workflow: Workflow) => {
        return workflow.id === this.instancePk;
      });
      if (!instance)
        throw new Error(
          "Couldn't determine the workflow category you are looking for.",
        );
      instance.is_active = !instance.is_active;
      this.workflowService
        .updateWorkflow(instance)
        .then(() => {
          // TODO: Success message.
        })
        .catch(() => {
          // TODO: Error message
        });
    } else {
      instance = this.workflowService.workflowCategories.find(
        (category: WorkflowCategory) => {
          return category.id === this.instancePk;
        },
      );
      if (!instance)
        throw new Error(
          "Couldn't determine the workflow category you are looking for.",
        );

      instance.is_active = !instance.is_active;
      this.workflowService
        .updateWorkflowCategory(instance)
        .then(() => {
          // TODO: Success message.
        })
        .catch(() => {
          // TODO: Error message
        });
    }

    this.closeModal();
  };

  onEditClicked = () => {
    if (this.isWorkflow) {
      this.onWorkflowEditClicked(this.instance);
    } else {
      this.onWorkflowCategoryEditClicked(this.instance);
    }
  };

  onWorkflowEditClicked = (workflow: any) => {
    this.router.navigateByUrl("workflow/update", {
      state: { workflow: workflow },
    });
    this.closeModal();
  };

  onWorkflowCategoryEditClicked = (workflow_category: any) => {
    this.router.navigateByUrl("workflow/category/update", {
      state: { workflow_category: workflow_category },
    });
    this.closeModal();
  };

  closeModal = () => {
    this.modalService.close(this.id);
  };
}
