<div>
  <span i18n="workflows:workflow title" class="gaspar-title">
    <div class="aligned">
      {{ title }}
      <img src="assets/images/workflow/workflow-black.svg" /></div
  ></span>
  <div class="status-bar">
    <app-status-bar
      [totalSteps]="totalSteps"
      [activeSteps]="currentStep"
    ></app-status-bar>
  </div>
  <div *ngIf="!isLoading">
    <!-- Workflow creation -->
    <app-workflow-create-step
      [isEdit]="workflowToEdit !== undefined"
      [workflowForm]="workflowForm"
      [active]="currentStep === 1"
      (submitEvent)="onWorkflowSave($event)"
    ></app-workflow-create-step>
    <!-- Select type of workflow -->
    <app-workflow-type-step
      [active]="currentStep === 2"
      [availableTypes]="workflowForms"
      [selectedTypeIndex]="selectedTypeIndex"
      (submitEvent)="onTypeSave($event)"
      (backEvent)="onBackStep()"
    ></app-workflow-type-step>

    <!-- Select integrations and actions... -->
    <app-workflow-integrations-step
      [active]="currentStep === 3"
      (submitEvent)="onIntegrationsSave($event)"
      (backEvent)="onBackStep()"
      [integrations]="integrations"
      [selectedWorkflowIntegrations]="selectedIntegrations"
    ></app-workflow-integrations-step>
    <!-- Select time to execute -->
    <!-- <app-workflow-kick-off-step
      [active]="currentStep === 4"
      (submitEvent)="onKickOffSave($event)"
      (backEvent)="onBackStep()"
    >
    </app-workflow-kick-off-step> -->
    <!-- Overview workflow. It needs to know all the selected values.-->
    <app-workflow-review-step
      [workflowForm]="workflowForm"
      [type]="type"
      [selectedIntegrations]="selectedIntegrations"
      [kickOffForm]="kickOffForm"
      [active]="currentStep === 4"
      (submitEvent)="onReviewSave($event)"
      (backEvent)="onBackStep()"
    >
    </app-workflow-review-step>
  </div>
</div>
