<div *ngIf="active">
  <div class="title">Review your workflow</div>
  <div class="container">
    <!-- Workflow preview -->
    <div class="review-card">
      <div class="half">
        <div class="inner">
          <div class="header">
            <div class="small-text gray">Worfklow name</div>
            <div class="medium-text">{{ workflowForm.value.name }}</div>
          </div>
        </div>
        <div class="inner">
          <div class="header">
            <div class="small-text gray">Worfklow category</div>
            <div class="medium-text">
              {{ workflowCategoryName }}
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="small-text gray">Description</div>
        <div class="small-text">{{ workflowForm.value.description }}</div>
      </div>
    </div>
    <div class="review-card">
      <div class="content">
        <div class="small-text gray">Workflow Type</div>
        <br />
        <div class="medium-text">{{ type.name }}</div>
        <!-- <div class="small-text">Workflow type description</div> -->
      </div>
    </div>
    <div
      class="review-card blue-border"
      *ngFor="
        let wIntegration of selectedFilteredIntegrations;
        let index = index
      "
    >
      <div class="content" *ngIf="index === 0">
        <div class="small-text gray">Integrations and Actions</div>
      </div>
      <div class="integration-card">
        <span class="integration-name">
          {{ wIntegration.integration.name }}</span
        >
        <div class="inner-card" *ngFor="let worker of wIntegration.workers">
          <span class="action-name">{{ worker.name }} </span>
          <div class="form-container" *ngIf="worker.form_values">
            <div class="key small-text gray">
              <span *ngFor="let key of workerFormKeys(worker)">{{ key }} </span>
            </div>
            <div class="value small-text">
              <span *ngFor="let value of workerFormValues(worker)"
                >{{ value }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- More cards... -->
    </div>
    <!-- <div class="review-card">
      <div class="content">
        <div class="small-text gray">Worfklow schedule</div>
        <br />
        <div class="small-text">
          Kick off on: <b> {{ kickOffForm.option }}</b>
        </div>
      </div>
    </div> -->
    <div class="actions">
      <a mat-flat-button class="pointer" (click)="onCancel()"> Cancel </a>
      <div class="right">
        <a mat-flat-button (click)="onBack()" class="pointer"> Back </a>
        <button mat-raised-button color="primary" (click)="onReviewSave()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>
