<div *ngIf="active">
  <div class="title" i18n="workflow type step: Workflow Type">
    Event Actions
    <img
      src="assets/images/info.svg"
      class="pointer"
      alt="tooltop workflow type"
    />
  </div>
  <div
    class="subtitle"
    i18n="
      workflow type step: Determine the type of this workflow. What does it do?
    "
  >
    Add integrations and create step by step workflow.
  </div>
  <div class="container">
    <div
      class="size box-integration"
      *ngFor="let wIntegration of selectedWorkflowIntegrations"
    >
      <div class="content">
        <img
          [src]="wIntegration.integration.icon_enabled"
          alt="integration icon"
          *ngIf="wIntegration.integration.icon_enabled"
        />
        <span style="margin-top: 5px">{{ wIntegration.integration.name }}</span>
        <!-- Loop through the selected workers for this integration. -->
        <div
          class="workers-inner-container w-100"
          *ngFor="let worker of wIntegration.workers; let index = index"
        >
          <div class="action">
            <!-- Delete item on click. -->
            <img
              class="pointer"
              (click)="
                removeWorkerFromIntegration(wIntegration.integration, worker)
              "
              src="assets/images/delete-red.svg"
            />
            <span> {{ worker.name }}</span>
          </div>
          <span class="no-conf-needed" *ngIf="!worker.definition"
            >No configuration needed</span
          >
          <!-- Render Form. -->
          <div class="form-container">
            <app-form
              *ngIf="worker.definition"
              [definition]="worker.definition"
              appearance="legacy"
              (formStatus)="worker.form_values = $event"
              (formRef)="addFormToState($event)"
              [values]="worker.form_values || {}"
            ></app-form>
          </div>
        </div>
        <span
          *ngIf="
            getWorkersForIntegration(wIntegration.integration).length === 0
          "
          class="no-actions-text"
          >No actions available.</span
        >
        <mat-form-field
          class="w-100 select-field"
          appearance="outline"
          *ngIf="
            getWorkersForIntegration(wIntegration.integration).length !== 0
          "
        >
          <mat-select
            #matSelectRef
            placeholder="Select an action..."
            (selectionChange)="onSelectionChange($event, wIntegration)"
          >
            <mat-option
              *ngFor="
                let worker of getWorkersForIntegration(wIntegration.integration)
              "
              [value]="worker.name"
            >
              {{ worker.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="error">
      <mat-error class="error" *ngFor="let error of errors">
        - {{ error }}
      </mat-error>
    </div>
    <div
      class="size box pointer"
      (click)="openIntegrationsModal('integrations_modal')"
      *ngIf="selectedWorkflowIntegrations.length !== integrations.length"
    >
      <span>
        <img src="assets/images/workflow/plus-blue.svg" alt="plus image" />
        Add integration</span
      >
    </div>

    <div class="actions">
      <a mat-flat-button class="pointer" (click)="onCancel()"> Cancel </a>
      <div class="right">
        <a mat-flat-button (click)="onBack()" class="pointer"> Back </a>
        <button
          mat-raised-button
          color="primary"
          (click)="onWorkflowSave()"
          [disabled]="false"
        >
          Next
        </button>
      </div>
    </div>
    <app-modal id="integrations_modal">
      <app-integrations-modal
        id="integrations_modal"
        [integrations]="integrations"
        [editingWorkflowIntegrations]="selectedWorkflowIntegrations"
        (submitEvent)="onIntegrationsSelect($event)"
      ></app-integrations-modal>
    </app-modal>
  </div>
</div>
