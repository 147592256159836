<form class="gaspar-form" (ngSubmit)="onSave()" [formGroup]="expertiseForm">
  <mat-dialog-content>
    <mat-dialog-header>
      <div i18n="edit-expertise-modal:Edit tags title" class="title">
        Edit tags
      </div>
      <hr />
    </mat-dialog-header>
    <mat-form-field class="w-100" appearance="fill">
      <mat-label i18n="edit-expertise-modal:Expertise tags">
        Expertise tags
      </mat-label>
      <input
        matInput
        type="search"
        formControlName="query"
        [mdePopoverTriggerFor]="tagPopover"
        mdePopoverTriggerOn="click"
        #popoverTrigger="mdePopoverTrigger"
        [mdePopoverBackdropCloseOnClick]="true"
        (input)="searchChanged()"
      />
      <mat-error *ngIf="this.expertiseForm.invalid">
        {{ getErrorMessage() }}
      </mat-error>
    </mat-form-field>
    <mde-popover
      #tagPopover="mdePopover"
      [mdePopoverOverlapTrigger]="false"
      [mdePopoverCloseOnClick]="true"
    >
      <div class="expertise-list-popover">
        <div
          class="item"
          *ngFor="let tag of tags"
          [class.assigned]="isAssigned(tag)"
          (click)="onExpertiseTagSelected(tag)"
        >
          {{ tag.name }}
          <div class="assigned-container" *ngIf="isAssigned(tag)">
            <img
              src="assets/images/green-check.svg"
              alt="check"
              *ngIf="isAssigned(tag)"
              i18n="edit-expertise-modal:Assigned"
            />Assigned
          </div>
        </div>
        <p
          class="item non-clickable"
          *ngIf="tags.length === 0"
          i18n="edit-expertise-modal:No matching tag..."
        >
          No matching tag...
        </p>
      </div>
    </mde-popover>
    <p
      class="help-text"
      *ngIf="expertiseForm.controls.query.value.length === 0"
      i18n="edit-expertise-modal:Start typing expertise tags"
    >
      Start typing expertise tags in searchfield
    </p>
    <div class="tags-container">
      <div class="tag-container" *ngFor="let tag of selectedTags">
        <app-tag [tag]="tag">
          <img
            src="assets/images/circled-close.svg"
            alt="close"
            (click)="onExpertiseTagRemove(tag)"
            class="img-margin"
          />
        </app-tag>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="actions">
    <button
      mat-flat-button
      class="pointer"
      (click)="onCancel()"
      i18n="edit-expertise-modal:Cancel button"
    >
      Cancel
    </button>
    <button
      mat-raised-button
      color="primary"
      [class.spinner]="isLoading"
      [disabled]="isLoading"
      i18n="edit-expertise-modal:Submit button"
    >
      Submit
    </button>
  </mat-dialog-actions>
</form>
