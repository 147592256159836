import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    // Interceptor which includes the token -if exists - to every request that is going to be made
    if (this.authService.token) {
      const authorizedRequest = request.clone({
        headers: request.headers.set(
          "Authorization",
          `Token ${this.authService.token}`,
        ),
      });
      return next.handle(authorizedRequest);
    } else {
      return next.handle(request);
    }
  }
}
