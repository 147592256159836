import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { URLS } from "../../constants/urls";
import { NormalResponse } from "../../models/common";
import {
  Integration,
  IntegrationConfig,
  IntegrationInfo,
} from "../../models/integration";
import { Worker } from "../../models/worker";
import { WorkflowIntegration } from "../../models/workflow";
import { ServiceModule } from "../service.module";
import { WorkflowService } from "../workflow/workflow.service";

@Injectable({
  providedIn: "root",
})
export class IntegrationService extends ServiceModule {
  workers: Worker[] = [];
  integrations: WorkflowIntegration[] = [];

  constructor(
    private http: HttpClient,
    private workflowService: WorkflowService,
  ) {
    super();
  }

  _findAvailableWorkers = (workflowIntegrations: WorkflowIntegration[]) => {
    // Based on all the retrieved integrations, find all the available workers that are supported.
    let workers: Worker[] = [];

    for (let integration of workflowIntegrations) {
      let i = 0;
      for (let worker of integration.workers) {
        let workerExists: boolean = workers.some(element => {
          return element.name === worker.name;
        });

        if (workerExists) continue;
        else {
          worker.id = i;
          workers.push(worker);
        }
        i++;
      }
    }
    return workers;
  };

  getIntegrationWorkers = () => {
    if (this.workflowService.workflowWorkersNeedRefresh) {
      return this.http
        .get<WorkflowIntegration[]>(URLS.INTEGRATION_WORKER_LIST)
        .toPromise()
        .then((integrationActions: WorkflowIntegration[]) => {
          this.workers = this._findAvailableWorkers(integrationActions);
          this.integrations = integrationActions;
          this.workflowService.workflowWorkersNeedRefresh = false;
          return integrationActions;
        });
    } else {
      return this.integrations;
    }
  };

  getIntegrations = () => {
    return this.http
      .get<Integration[]>(URLS.INTEGRATION_LIST)
      .toPromise()
      .then((integrations: Integration[]) => {
        return integrations;
      });
  };

  getIntegration = (url: string) => {
    return this.http
      .get<IntegrationInfo>(url)
      .toPromise()
      .then((integration: IntegrationInfo) => {
        return integration;
      })
      .catch(() => null);
  };

  getIntegrationConfig = (url: string) => {
    return this.http
      .get<IntegrationConfig>(url)
      .toPromise()
      .then((config: IntegrationConfig) => {
        return config;
      });
  };

  updateIntegrationConfig = (
    url: string,
    data: {
      [key: string]: any;
    },
  ) => {
    return this.http
      .post<NormalResponse>(url, data)
      .toPromise()
      .then((res: NormalResponse) => {
        return res;
      });
  };

  deleteIntegration = (url: string) => {
    return this.http.delete<NormalResponse>(url).toPromise();
  };

  triggerManualSync = (url: string, plugin_name: string) => {
    return this.http
      .post<NormalResponse>(url + plugin_name, {})
      .toPromise()
      .then((res: NormalResponse) => {
        return res;
      });
  };
}
