<div class="bar-chart-container">
  <google-chart
    *ngIf="graphData.length !== 0"
    [title]="title"
    [type]="type"
    [data]="graphData"
    [columnNames]="columnNames"
    [options]="options"
    [width]="width"
    [height]="height"
  >
  </google-chart>
</div>
