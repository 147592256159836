import { FormFieldType } from "../models/forms";
import {
  Integration,
  IntegrationConfig,
  IntegrationInfo,
} from "../models/integration";

export const INTEGRATIONS: Integration[] = [
  {
    name: "Confluence",
    url: "/integrations/confluence/",
    code: "confluence",
    icon_enabled: "static/confluence-enabled.svg",
    icon_disabled: "static/confluence-disabled.svg",
  },
  {
    name: "Fresh Service",
    url: "/integrations/freshservice/",
    code: "freshservice",
    icon_enabled: "static/freshservice-enabled.svg",
    icon_disabled: "static/freshservice-disabled.svg",
  },
  {
    name: "GitLab",
    url: "/integrations/gitlab/",
    code: "gitlab",
    icon_enabled: "static/gitlab-enabled.svg",
    icon_disabled: "static/gitlab-disabled.svg",
  },
  {
    name: "Google Drive",
    url: "/integrations/gdrive/",
    code: "gdrive",
    icon_enabled: "static/gdrive-enabled.svg",
    icon_disabled: "static/gdrive-disabled.svg",
  },
  {
    name: "Google Workspace",
    url: "/integrations/gsuite/",
    code: "gsuite",
    icon_enabled: "static/gsuite-enabled.svg",
    icon_disabled: "static/gsuite-disabled.svg",
  },
  {
    name: "HelpJuice",
    url: "/integrations/juice/",
    code: "juice",
    icon_enabled: "static/juice-enabled.svg",
    icon_disabled: "static/juice-disabled.svg",
  },
  {
    name: "Jira Service Management",
    url: "/integrations/jira/",
    code: "jira",
    icon_enabled: "static/jira-enabled.svg",
    icon_disabled: "static/jira-disabled.svg",
  },
  {
    name: "Office 365",
    url: "/integrations/ad-sso/",
    code: "ad-sso",
    icon_enabled: "static/ad-sso-enabled.svg",
    icon_disabled: "static/ad-sso-disabled.svg",
  },
  {
    name: "Okta",
    url: "/integrations/okta/",
    code: "okta",
    icon_enabled: "static/okta-enabled.svg",
    icon_disabled: "static/okta-disabled.svg",
  },
  {
    name: "OneDrive",
    url: "/integrations/onedrive/",
    code: "onedrive",
    icon_enabled: "static/onedrive-enabled.svg",
    icon_disabled: "static/onedrive-disabled.svg",
  },
  {
    name: "Slack",
    url: "/integrations/slack/",
    code: "slack",
    icon_enabled: "static/slack-enabled.svg",
    icon_disabled: "static/slack-disabled.svg",
  },
  {
    name: "Teams",
    url: "/integrations/teams/",
    code: "teams",
    icon_enabled: "static/teams-enabled.svg",
    icon_disabled: "static/teams-disabled.svg",
  },
];

export const INTEGRATION_INFO: IntegrationInfo[] = [
  {
    is_active: false,
    is_simple: false,
    url: "https://slack.com/oauth/v2/authorize?response_type=code&client_id=627310952322.1462588234055&redirect_uri=https%3A%2F%2FTrue%2Fintegrations%2Foauth%2F&scope=app_mentions%3Aread+channels%3Ahistory+chat%3Awrite+commands+im%3Ahistory+reactions%3Aread+reactions%3Awrite+users%3Aread+users%3Aread.email&state=test%3Aslack%3A93b85f4a7f1f06413c6a227487492cacf1213fd8",
    details: {},
    config_url: "http://localhost:8000/integrations/slack/config/",
    detail_message: "Inactive",
    last_token_refresh_http_status: 200,
    last_token_status_change_at: "2022-11-03T15:16:04Z",
    show_reset_config_button: false,
    show_manual_sync_knowledge_button: false,
    show_manual_sync_knowledge_in_progress: false,
    plugin_name: "plugin_name",
  },
];

export const INTEGRATION_CONFIG: IntegrationConfig[] = [
  {
    definition: [
      {
        name: "private_channel",
        required: true,
        type: FormFieldType.STR,
        display_name: "IT private channel",
        tooltip: "well, we have no idea what is this for",
      },
      {
        name: "test date",
        required: true,
        type: FormFieldType.DATE,
        display_name: "Date for IT",
        tooltip: null,
      },
      {
        name: "test datetime",
        required: true,
        type: FormFieldType.DATETIME,
        display_name: "Datetime for channel",
        tooltip: null,
      },
      {
        name: "test address",
        required: true,
        type: FormFieldType.ADDRESS,
        display_name: "address for onboarding",
        tooltip: null,
      },
      {
        name: "age",
        required: false,
        type: FormFieldType.INT,
        display_name: "Your age",
        tooltip: null,
      },
      {
        name: "public_channel",
        required: false,
        type: FormFieldType.STR,
        display_name: "Public channel: Channel to watch",
        tooltip: null,
      },
      {
        name: "keywords",
        required: false,
        type: FormFieldType.STR,
        display_name: "Public channel: Keywords to respond to",
        tooltip: null,
      },
      {
        name: "emoji",
        required: false,
        type: FormFieldType.BOOL,
        display_name: "emoji",
        tooltip: null,
      },
      {
        name: "all",
        required: false,
        type: FormFieldType.BOOL,
        display_name: "All",
        tooltip: "well, we have no idea what is this for",
      },
      {
        name: "select field",
        required: false,
        type: FormFieldType.SELECT,
        values: ["value", "value1", "value2"],
        display_name: "Select a value for the select field",
        tooltip: "This is a select value",
      },
    ],
    values: {
      private_channel: "debugging-slackbot",
      "test date": "2021-09-21T09:19:04.552360Z",
      "test datetime": "2021-09-21T09:19:04.552360Z",
      "select field": "value",
      all: true,
      emoji: false,
      keywords: "all",
      public_channel: "debugging-slackbot",
      age: 18,
      "test address": "test address",
    },
  },
];
