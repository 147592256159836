import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Location } from "@angular/common";
import { WorkflowForm } from "src/app/shared/models/workflow";

@Component({
  selector: "app-workflow-type-step",
  templateUrl: "./workflow-type-step.component.html",
  styleUrls: ["./workflow-type-step.component.scss"],
})
export class WorkflowTypeStepComponent implements OnInit {
  @Input() active: boolean = false;
  @Input() selectedTypeIndex: number | null = null;
  @Input() availableTypes: WorkflowForm[] = [];
  @Output() submitEvent = new EventEmitter();
  @Output() backEvent = new EventEmitter();

  constructor(private location: Location) {}

  ngOnInit(): void {}

  get workflowTypes() {
    return this.availableTypes;
  }

  selectTypeOfWorkflow = (id: number) => {
    this.selectedTypeIndex = id;
  };

  onCancel = () => {
    this.location.back();
  };

  onBack = () => {
    this.backEvent.emit();
  };

  onTypeSave(): void {
    let selectedWorkflowType;
    for (let i = 0; i < this.workflowTypes.length; i++) {
      if (i === this.selectedTypeIndex) {
        selectedWorkflowType = this.workflowTypes[i];
        break;
      }
    }
    if (!selectedWorkflowType) {
      throw new Error(
        "Couldn't determine what workflow type has been selected!",
      );
    }
    this.submitEvent.emit(selectedWorkflowType);
  }
}
