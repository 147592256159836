<h2>{{ title }}</h2>
<form
  class="gaspar-form"
  [ngClass]="form_class"
  (ngSubmit)="onSubmit($event)"
  [formGroup]="form"
>
  <div *ngFor="let field of definition; let first = first; let last = last">
    <div class="vertical-line half" *ngIf="hasDots"></div>
    <!-- SELECT FIELD -->
    <div *ngIf="field.type === 'select'">
      <div
        class="vertical-line"
        *ngIf="hasDots && (!isLastItem || !last)"
      ></div>
      <mat-form-field [appearance]="appearance" class="w-100">
        <img
          src="assets/images/workflow/blue-circle.svg"
          class="blue-circle"
          *ngIf="hasDots"
        />
        <mat-label>{{ display_value(field) }}</mat-label>
        <mat-select [multiple]="field.multi" [formControlName]="field.name">
          <mat-select
            *ngIf="!field.multi"
            [formControlName]="field.name"
          ></mat-select>
          <mat-option>
            <ngx-mat-select-search
              [formControl]="valuesFilterCtrl"
              placeholderLabel="Type to search"
            ></ngx-mat-select-search>
          </mat-option>
          <mat-option
            class="option-clear"
            *ngIf="!field.required && !valuesFilterCtrl.value && !field.multi"
            i18n="form component: clear selection option"
            >Clear selection</mat-option
          >
          <mat-option
            [value]="value"
            *ngFor="let value of filteredValues(field)"
          >
            {{ value }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="this.form.controls[field.name].invalid">
          {{ getErrorMessage(field.name) }}
        </mat-error>
      </mat-form-field>
    </div>

    <!-- CHECKBOX - BOOL -->
    <div *ngIf="field.type === 'bool'">
      <div
        class="vertical-line"
        *ngIf="hasDots && (!isLastItem || !last)"
      ></div>
      <section class="checkbox-section">
        <div
          class="vertical-line"
          *ngIf="hasDots && (!isLastItem || !last)"
          style="margin-top: -20px"
        ></div>
        <img
          src="assets/images/workflow/blue-circle.svg"
          class="blue-circle checkbox"
          *ngIf="hasDots"
        />
        <mat-checkbox [formControlName]="field.name">
          <span class="text-break">{{ display_value(field) }}</span>
        </mat-checkbox>
        <mat-error *ngIf="this.form.controls[field.name].invalid">
          {{ getErrorMessage(field.name) }}
        </mat-error>
      </section>
    </div>

    <!-- PASSWORD -->
    <div *ngIf="field.type === 'password'">
      <div
        class="vertical-line"
        *ngIf="hasDots && (!isLastItem || !last)"
      ></div>
      <mat-form-field [appearance]="appearance" class="w-100">
        <mat-label>{{ display_value(field) }}</mat-label>
        <img
          src="assets/images/workflow/blue-circle.svg"
          class="blue-circle"
          *ngIf="hasDots"
        />
        <input
          matInput
          color="accent"
          [type]="input_type(field.type)"
          [formControlName]="field.name"
        />
        <mat-error *ngIf="this.form.controls[field.name].invalid">
          {{ getErrorMessage(field.name) }}
        </mat-error>
      </mat-form-field>
    </div>

    <!-- SUPPORTED TYPES 
        color
        date
        datetime-local
        email
        month
        number
        password
        search
        tel
        text
        time
        url
        week 
      -->

    <!-- INPUT (STR - INT - ADDRESS) -->
    <div
      *ngIf="
        field.type === 'str' || field.type === 'int' || field.type === 'address'
      "
    >
      <div
        class="vertical-line"
        *ngIf="hasDots && (!isLastItem || !last)"
      ></div>
      <mat-form-field [appearance]="appearance" class="w-100">
        <mat-label>{{ display_value(field) }}</mat-label>
        <img
          src="assets/images/workflow/blue-circle.svg"
          class="blue-circle"
          *ngIf="hasDots"
        />
        <input
          matInput
          color="accent"
          [type]="input_type(field.type)"
          [formControlName]="field.name"
        />
        <mat-error *ngIf="this.form.controls[field.name].invalid">
          {{ getErrorMessage(field.name) }}
        </mat-error>
      </mat-form-field>
    </div>

    <!-- DATETIME -->
    <div *ngIf="field.type === 'datetime'">
      <div
        class="vertical-line"
        *ngIf="hasDots && (!isLastItem || !last)"
      ></div>
      <mat-form-field [appearance]="appearance" class="w-100">
        <mat-label>{{ display_value(field) }}</mat-label>
        <mat-error *ngIf="this.form.controls[field.name].invalid">
          {{ getErrorMessage(field.name) }}
        </mat-error>
        <input matInput type="datetime-local" [formControlName]="field.name" />
      </mat-form-field>
    </div>

    <!-- DATE -->
    <div *ngIf="field.type === 'date'">
      <mat-form-field [appearance]="appearance" class="w-100">
        <mat-label>{{ display_value(field) }}</mat-label>
        <img
          src="assets/images/workflow/blue-circle.svg"
          class="blue-circle"
          *ngIf="hasDots"
        />
        <input
          matInput
          [matDatepicker]="picker"
          [formControlName]="field.name"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error *ngIf="this.form.controls[field.name].invalid">
          {{ getErrorMessage(field.name) }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <mat-error *ngIf="this.form.invalid">
    {{ getErrorMessage(null) }}
  </mat-error>

  <button
    *ngIf="cancelEvent.observers.length > 0"
    mat-button
    class="form-button"
    (click)="onCancel($event)"
  >
    {{ cancelText }}
  </button>
  <div
    class="vertical-line"
    *ngIf="hasDots && submitEvent.observers.length > 0"
    style="margin-top: -45px"
  ></div>
  <div
    class="vertical-line half"
    *ngIf="hasDots && submitEvent.observers.length > 0"
    style="margin-top: 20px"
  ></div>
  <div [style.text-align]="submitTextAlignment">
    <button
      *ngIf="submitEvent.observers.length > 0"
      type="submit"
      mat-raised-button
      class="form-button"
      color="primary"
      [class.spinner]="isSubmitLoading"
      [disabled]="isSubmitLoading"
    >
      <span [class.login-form]="type === 'login'">{{ submitText }}</span>
    </button>
  </div>
</form>
