import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-box",
  templateUrl: "./box.component.html",
  styleUrls: ["./box.component.scss"],
})
export class BoxComponent implements OnInit {
  @Input() image: string = "N/A";
  @Input() color: string = "#000";
  @Input() title: string = "Title";
  @Input() totalNumber: number = 0;

  constructor() {}

  ngOnInit(): void {}
}
