import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import * as Sentry from "@sentry/angular";

if (environment.production) {
  enableProdMode();
}

if (environment.sentry_dsn) {
  Sentry.init({
    dsn: environment.sentry_dsn,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => {
    throw new Error(err);
  });
