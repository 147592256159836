<span i18n="user-management:user management title" class="gaspar-title">
  User management
</span>
<div class="scrollable">
  <div class="tabs-container">
    <button
      mat-flat-button
      i18n="user-management:admin tab"
      class="tab-btn"
      [class.selected]="onSelectedTabChange('MANAGER')"
      (click)="onChangeUserType('MANAGER')"
    >
      Admins
    </button>
    <div class="grey-dot"></div>
    <button
      mat-flat-button
      i18n="user-management:agent tab"
      class="tab-btn"
      [class.selected]="onSelectedTabChange('AGENT')"
      (click)="onChangeUserType('AGENT')"
    >
      Agents
    </button>
    <div class="grey-dot"></div>
    <button
      mat-flat-button
      i18n="user-management:user tab"
      class="tab-btn"
      [class.selected]="onSelectedTabChange('ENDUSER')"
      (click)="onChangeUserType('ENDUSER')"
    >
      Users
    </button>
    <div class="grey-dot"></div>
    <button
      mat-flat-button
      i18n="user-management:archived tab"
      class="tab-btn"
      [class.selected]="onSelectedTabChange('ARCHIVED')"
      (click)="onChangeUserType('ARCHIVED')"
    >
      Archived
    </button>

    <mat-form-field class="right" appearance="outline">
      <form [formGroup]="searchForm">
        <div class="inner">
          <input
            matInput
            type="search"
            formControlName="search"
            placeholder="Type here to search..."
          />
          <img src="assets/images/user-management/search_icon.svg" />
        </div>
      </form>
    </mat-form-field>
  </div>
  <mat-table #table [dataSource]="dataSource" matSort class="gaspar-table">
    <div
      *matNoDataRow
      class="no-available-data"
      i18n="user-management:no data available"
    >
      Theres no data available
    </div>
    <ng-container matColumnDef="name">
      <mat-header-cell
        i18n="user-management:name"
        *matHeaderCellDef
        class="base-col"
      >
        Name
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="role">
      <mat-header-cell
        i18n="user-management:role"
        *matHeaderCellDef
        class="base-col"
      >
        Role
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <mat-form-field class="role-field">
          <mat-select
            (selectionChange)="userRoleChanged(element, $event)"
            [(ngModel)]="element.role"
          >
            <mat-option
              [value]="option.value"
              *ngFor="let option of gasparRoles"
            >
              {{ option.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell
        i18n="user-management:email"
        *matHeaderCellDef
        class="base-col"
        mat-sort-header
      >
        Email
      </mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="last-activity">
      <mat-header-cell
        i18n="user-management:last activity"
        *matHeaderCellDef
        class="base-col"
      >
        Last activity
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ lastActivityPrettified(element) }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="expertise">
      <mat-header-cell
        i18n="user-management:expertise tags"
        *matHeaderCellDef
        mat-sort-header
      >
        Expertise
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="tags-container">
          <div class="tag-container" *ngFor="let tag of element.tags">
            <app-tag [tag]="tag"></app-tag>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell
        i18n="user-management:actions"
        *matHeaderCellDef
        class="base-col"
      >
        Actions
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <img
          class="action-icon icon pointer"
          src="assets/images/edit.svg"
          alt="edit user management"
          (click)="openModal(element)"
        />
        <!-- <img
          class="action-icon icon pointer"
          src="assets/images/delete.svg"
          alt="edit user management"
        /> -->
      </mat-cell>
    </ng-container>

    <mat-header-row
      *matHeaderRowDef="displayedColumns"
      disabled
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    *ngIf="paginatedUsers"
    [pageIndex]="paginatorPageIndex"
    [length]="paginatedUsers.count"
    [pageSize]="20"
    [showFirstLastButtons]="true"
    (page)="onPageChange($event)"
    aria-label="Select page"
  >
  </mat-paginator>
</div>
