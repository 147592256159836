import { Component, Input, OnInit } from "@angular/core";
import { MetricsResolution } from "src/app/shared/models/metrics";

@Component({
  selector: "app-top-level-statistics",
  templateUrl: "./top-level-statistics.component.html",
  styleUrls: ["./top-level-statistics.component.scss"],
})
export class TopLevelStatisticsComponent implements OnInit {
  @Input() data: MetricsResolution = {} as MetricsResolution;

  constructor() {}

  ngOnInit(): void {}
}
