import {
  Component,
  Input,
  ChangeDetectionStrategy,
  forwardRef,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

import { MatSlideToggle } from "@angular/material/slide-toggle";

@Component({
  selector: "app-mat-slide-toggle-icon",
  exportAs: "matSlideToggleIcon",
  templateUrl: "./slide-toggle-icon.component.html",
  styleUrls: ["./slide-toggle-icon.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SlideToggleIconComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SlideToggleIconComponent extends MatSlideToggle {
  @Input() enabledIcon: string = "check";
  @Input() disabledIcon: string = "";
  @Input() disabled: boolean = false;
  @Input() disableRipple: boolean = false;
  @Input() tabIndex: number = -1;
}
