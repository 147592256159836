import { UserRole } from "../models/user";

export const GASPAR = {
  TITLE: "Gaspar",
  ADMIN_PORTAL: "Admin portal",
  LOGIN_URLS: ["", "redirect"],
  DEFAULT_SCREEN: "dashboard",
  CONTACT_INFO: "info@gaspardesk.com",
  CUSTOM_INTEGRATION_ACTION: "custom_action",
  CUSTOM_TICKET_WORKER: "custom_ticket",
  USER_ROLE_OPTIONS: [
    {
      value: UserRole.MANAGER,
      label: "Admin",
    },
    {
      value: UserRole.AGENT,
      label: "Agent",
    },
    {
      value: UserRole.ENDUSER,
      label: "User",
    },
  ],
  NON_TITLE_INTEGRATIONS: [
    "gsuite",
    "wrike",
    "dropbox",
    "ivanti",
    "zendesk",
    "servicenow",
    "duo",
    "meraki",
    "docusign",
    "jumpcloud",
    "tableau",
    "looop",
    "hibob",
    "ad-devops",
  ],
};
