<div class="container">
  <h2 i18n="error-page: Something went wrong">Something went wrong</h2>
  <img
    src="assets/images/login/auth-error.svg"
    alt="integration"
    class="sidebar-icon"
  />
  <div>
    <p class="error-message">{{ errorMessage }}</p>
  </div>
  <button
    *ngIf="backButton"
    mat-raised-button
    color="primary"
    (click)="goBack()"
  >
    Go back
  </button>
</div>
