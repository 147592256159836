<div *ngIf="!isLoading">
  <div class="title-header scrollable">
    <span i18n="dashboard:dashboard title" class="gaspar-title">
      Dashboard
    </span>
    <mat-form-field i18n="dashboard:duration dropdown" appearance="outline">
      <mat-select
        [(value)]="dateSelected"
        (selectionChange)="onDateChanged($event)"
        required
      >
        <mat-option value="1">Last 1 day</mat-option>
        <mat-option value="7">Last 7 days</mat-option>
        <mat-option value="14">Last 14 days</mat-option>
        <mat-option value="30">Last 1 month</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <app-top-level-statistics
    *ngIf="!isLoading"
    [data]="data"
  ></app-top-level-statistics>
  <!-- Sankey Graph -->
  <app-sankey-graph
    *ngIf="chartLoadedOnce && !isLoading"
    [data]="data"
  ></app-sankey-graph>

  <!-- Missed opportunities chart -->
  <app-missed-opportunity-chart
    *ngIf="!isLoading"
    [data]="data"
  ></app-missed-opportunity-chart>

  <div style="margin-top: 20px">
    <span class="gaspar-sub-title">Automation requested by users</span>
  </div>
  <!-- Workers chart -->
  <app-column-workers-chart
    *ngIf="chartLoadedOnce && !isLoading"
    [data]="data.workers"
  >
  </app-column-workers-chart>

  <!-- Workflows chart -->
  <div class="workflow-container">
    <span class="gaspar-sub-title">Automation created by workflows</span>
    <div class="statistics">
      <app-box
        title="Creation Workflow"
        image="assets/images/dashboard/workflow.svg"
        [totalNumber]="data.workflow_creation"
        color="#EDAE65"
      >
      </app-box>
      <app-box
        title="Modification Workflow"
        image="assets/images/dashboard/workflow.svg"
        [totalNumber]="data.workflow_modification"
        color="#EDAE65"
      >
      </app-box>
    </div>
    <app-column-workflows-chart
      *ngIf="chartLoadedOnce && !isLoading"
      [data]="data.workflows"
    >
    </app-column-workflows-chart>

    <app-polus-query-table [data]="dataSource"></app-polus-query-table>
  </div>
</div>
