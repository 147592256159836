import { Component, Input, OnInit } from "@angular/core";
import { GASPAR } from "../../constants/gaspar";
import { STORAGE_KEYS } from "../../constants/localeStorageKeys";
import {
  faTags,
  faUserFriends,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import { Router } from "@angular/router";

export enum SidebarStatusEnum {
  CLOSED = "close",
  OPEN = "open",
}
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  @Input() isSidebarAvailable: boolean = false;

  GASPAR = GASPAR;
  usersIcon = faUserFriends;
  tagsIcon = faTags;
  dashboardIcon = faWarehouse;

  showSidebar: boolean = false;
  currentTab: string = "users";

  constructor(private router: Router) {}

  ngOnInit(): void {
    // Initialize the sidebar status from the local storage
    this.showSidebar =
      localStorage.getItem(STORAGE_KEYS.SIDEBAR) === SidebarStatusEnum.CLOSED
        ? false
        : true;

    if (!this.showSidebar) return;
    // Find the given path in order to use the correct class to the given sidebar's item
    let path = window.location.pathname;
    if (path.includes("dashboard")) {
      this.sidebarValue("dashboard");
    } else if (path.includes("users")) {
      this.sidebarValue("users");
    } else if (path.includes("tags")) {
      this.sidebarValue("tags");
    } else if (path.includes("integrations")) {
      this.sidebarValue("integrations");
    } else if (path.includes("workflows") || path.includes("workflow")) {
      this.sidebarValue("workflows");
    }
  }

  sidebarValue = (value: string) => {
    this.currentTab = value;
  };

  onSidebarTabChange = (value: string) => {
    switch (value) {
      case "dashboard":
        this.currentTab = value;
        this.router.navigateByUrl("dashboard");
        break;
      case "users":
        this.currentTab = value;
        this.router.navigateByUrl("users");
        break;
      case "tags":
        this.currentTab = value;
        this.router.navigateByUrl("tags");
        break;
      case "workflows":
        this.currentTab = value;
        this.router.navigateByUrl("workflows");
        break;
      case "integrations":
        this.currentTab = value;
        this.router.navigateByUrl("integrations");
        break;
      default:
        this.currentTab = "dashboard";
    }
  };
}
