export enum FormFieldType {
  DATE = "date",
  BOOL = "bool",
  DATETIME = "datetime",
  ADDRESS = "address",
  INT = "int",
  STR = "str",
  SELECT = "select",
  PASSWORD = "password",
}

export const FormFieldsOptions = [
  FormFieldType.DATE,
  FormFieldType.BOOL,
  FormFieldType.ADDRESS,
  FormFieldType.DATETIME,
  FormFieldType.INT,
  FormFieldType.STR,
  FormFieldType.SELECT,
  FormFieldType.PASSWORD,
];

export interface FormField {
  name: string;
  required: boolean;
  type: FormFieldType;
  values?: string[] | null;
  display_name?: string | null;
  tooltip?: string | null;
  multi?: boolean;
}

export interface Form {
  id?: number;
  name: string;
  is_active: boolean;
  is_system: boolean;
  definition: FormField[];
  values: object | {};
}
