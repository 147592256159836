import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { URLS } from "../../constants/urls";
import {
  Workflow,
  WorkflowCategory,
  WorkflowForm,
  WorkflowIntegration,
  WorkflowStartForm,
} from "../../models/workflow";
import { ServiceModule } from "../service.module";

@Injectable({
  providedIn: "root",
})
export class WorkflowService extends ServiceModule {
  workflowForms: WorkflowForm[] = [];
  workflowsSubject: BehaviorSubject<any> = new BehaviorSubject({});
  workflows: Workflow[] = [];
  workflowCategories: WorkflowCategory[] = [];
  workflowCategoriesSubject: BehaviorSubject<any> = new BehaviorSubject({});
  integrationActions: WorkflowIntegration[] = [];
  workflowWorkersNeedRefresh: boolean = true;

  constructor(private http: HttpClient) {
    super();
  }

  invalidateWorkersState = () => {
    this.workflowWorkersNeedRefresh = true;
  };

  getWorkflow = (id: number) => {
    return this.http
      .get<Workflow>(`${URLS.WORKFLOW}${id}`)
      .toPromise()
      .then((workflow: Workflow) => {
        return workflow;
      });
  };

  getWorkflows = () => {
    return this.http
      .get<Workflow[]>(URLS.WORKFLOW)
      .toPromise()
      .then((workflows: Workflow[]) => {
        this.workflows = workflows;
        return workflows;
      });
  };

  getWorkflowForm = (id: string) => {
    const url = `${URLS.WORKFLOW_FORM}${id}/form/`;

    return this.http
      .get<WorkflowForm>(url)
      .toPromise()
      .then((form: WorkflowForm) => {
        return form;
      });
  };

  getWorkflowCategories = () => {
    this.http
      .get<WorkflowCategory[]>(URLS.WORKFLOW_CATEGORY)
      .toPromise()
      .then((categories: WorkflowCategory[]) => {
        this.workflowCategories = categories;
      });
  };

  createWorkflowCategory = (workflow_category: WorkflowCategory) => {
    return this.http
      .post<WorkflowCategory>(URLS.WORKFLOW_CATEGORY, workflow_category)
      .toPromise()
      .then((result: WorkflowCategory) => {
        this.workflowCategories.push(result);
        this.workflowCategoriesSubject.next(result);
        return result;
      });
  };

  createWorkflow = (workflow: Workflow) => {
    return this.http
      .post<Workflow>(URLS.WORKFLOW, workflow)
      .toPromise()
      .then((result: Workflow) => {
        this.workflows.push(result);
        this.workflowsSubject.next(result);
        return result;
      });
  };

  startWorkflow = (id: number, form: WorkflowStartForm) => {
    return this.http
      .post(`${URLS.WORKFLOW}${id}/start/`, form)
      .toPromise()
      .then((result: any) => {
        return result;
      });
  };

  updateWorkflow = (workflow: Workflow) => {
    return this.http
      .patch(`${URLS.WORKFLOW}${workflow.id}/`, workflow)
      .toPromise()
      .then((result: any) => {
        this.workflowsSubject.next(result);
        const index = this.workflows.findIndex(
          (item: Workflow) => item.id === workflow.id,
        );
        if (index > -1) {
          this.workflows[index] = result;
        }
        return result;
      });
  };

  updateWorkflowCategory = (category: WorkflowCategory) => {
    return this.http
      .patch(`${URLS.WORKFLOW_CATEGORY}${category.id}/`, category)
      .toPromise()
      .then((result: any) => {
        this.workflowCategoriesSubject.next(result);
        const index = this.workflowCategories.findIndex(
          (item: WorkflowCategory) => item.id === category.id,
        );
        if (index > -1) {
          this.workflowCategories[index] = result;
        }
        return result;
      });
  };
}
