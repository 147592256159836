import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthenticationService } from "../../services/authentication/authentication.service";

@Component({
  selector: "app-error-page",
  templateUrl: "./error-page.component.html",
  styleUrls: ["./error-page.component.scss"],
})
export class ErrorPageComponent {
  errorMessage: string;
  backButton: boolean;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private authService: AuthenticationService,
  ) {
    this.errorMessage =
      this.router.getCurrentNavigation()?.extras.state?.error ||
      "Page not found!";
    this.backButton =
      this.router.getCurrentNavigation()?.extras.state?.backButton || false;

    this.spinner.hide();
  }

  goBack = () => {
    // Logouts the user - removes the token - redirects him to the startupp page
    // and reloads the page in order to clear the memory
    this.authService.logout(true);
  };
}
