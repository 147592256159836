<mat-drawer-container>
  <mat-drawer
    #drawer
    [opened]="showSidebar"
    class="sidebar-container"
    mode="side"
  >
    <div class="sidebar-header">
      <span i18n="sidebar:admin portal title" class="sidebar-title">
        {{ GASPAR.ADMIN_PORTAL }}
      </span>
    </div>
    <div class="sidebar-content">
      <div class="sidebar-items">
        <p
          i18n="sidebar:Dashboard"
          class="sidebar-item pointer"
          [class.selected]="currentTab === 'dashboard'"
          (click)="onSidebarTabChange('dashboard')"
        >
          <fa-icon [icon]="dashboardIcon" class="sidebar-icon"></fa-icon
          >Dashboard
        </p>
        <p
          i18n="sidebar:User management"
          class="sidebar-item pointer"
          [class.selected]="currentTab === 'users'"
          (click)="onSidebarTabChange('users')"
        >
          <fa-icon [icon]="usersIcon" class="sidebar-icon"></fa-icon>User
          Management
        </p>
        <p
          i18n="sidebar:Tags"
          class="sidebar-item pointer"
          [class.selected]="currentTab === 'tags'"
          (click)="onSidebarTabChange('tags')"
        >
          <fa-icon [icon]="tagsIcon" class="sidebar-icon"></fa-icon>Tags
        </p>
        <p
          i18n="sidebar:Integrations"
          class="sidebar-item pointer"
          [class.selected]="currentTab === 'integrations'"
          (click)="onSidebarTabChange('integrations')"
        >
          <img
            src="assets/images/sidebar/integration.svg"
            alt="integration"
            class="sidebar-icon"
          />Integrations
        </p>
        <p
          i18n="sidebar:Workflows"
          class="sidebar-item pointer"
          [class.selected]="currentTab === 'workflows'"
          (click)="onSidebarTabChange('workflows')"
        >
          <img
            src="assets/images/sidebar/workflow.svg"
            alt="integration"
            class="sidebar-icon"
          />Workflows
        </p>
      </div>
    </div>
  </mat-drawer>
  <app-navbar
    [drawer]="drawer"
    [isSidebarAvailable]="isSidebarAvailable"
  ></app-navbar>
  <!-- The whole app is rendered within the sidebar -->
  <div class="main-container">
    <ng-content></ng-content>
  </div>
</mat-drawer-container>
