<div class="login-page-container" *ngIf="!isLoading">
  <div class="gaspar-card login-card" *ngIf="authDetail.type === 'email'">
    <div class="inner-padding">
      <img src="assets/images/login/login-logo-email.svg" alt="logo" />
      <p class="welcome-text" *ngIf="!error" style="margin: 15px 0 15px 0">
        Log in to your Gaspar AI account
      </p>
      <div class="gaspar-form login-form">
        <app-form
          *ngIf="loginFormWithEmail?.definition"
          type="login"
          [definition]="loginFormWithEmail!.definition"
          (submitEvent)="onSubmit($event)"
          submitTextAlignment="center"
          submitText="Log in"
        >
        </app-form>
      </div>
    </div>
  </div>
  <div class="gaspar-card login-card" *ngIf="authDetail.type !== 'email'">
    <img src="assets/images/login/login-logo.svg" alt="logo" />
    <p class="welcome-text" *ngIf="!error">
      Login with your single sign on<br />account below
    </p>
    <p class="welcome-text error" *ngIf="error">
      {{ error }}
    </p>
    <button
      mat-button
      class="social-login-btn google"
      i18n="login:Google"
      *ngIf="authDetail.type === 'google'"
      (click)="sso()"
    >
      <img src="assets/images/login/google-logo.svg" alt="google" />
      Sign in with Google
    </button>
    <button
      mat-button
      class="social-login-btn office"
      i18n="login:Office 365"
      *ngIf="authDetail.type === 'azure'"
      (click)="sso()"
    >
      <img src="assets/images/login/o365-logo.svg" alt="office 365" />
      Sign in with Office 365
    </button>
  </div>
</div>
