<mat-dialog-content>
  <mat-dialog-header>
    <div i18n="delete modal: Archive title" class="title">
      Archive {{ title }}
    </div>
    <hr />
  </mat-dialog-header>
  <div
    class="modal-container"
    i18n="delete modal: Are you sure you want to archive"
  >
    Are you sure you want to archive:
    <b> {{ data.item.name }} {{ title }}</b> ?
  </div>
</mat-dialog-content>
<mat-dialog-actions class="actions">
  <a mat-button (click)="onCancel()" i18n="delete modal: Cancel button">
    Cancel
  </a>
  <button
    mat-raised-button
    color="warn"
    (click)="onArchive()"
    i18n="delete modal: Archive button"
  >
    Archive
  </button>
</mat-dialog-actions>
