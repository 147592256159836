import { Component, OnInit } from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { GASPAR } from "src/app/shared/constants/gaspar";
import { Tag } from "src/app/shared/models/tag";
import { Worker } from "src/app/shared/models/worker";

import {
  Workflow,
  WorkflowCategory,
  WorkflowForm,
  WorkflowIntegration,
  WorkflowStep,
} from "src/app/shared/models/workflow";
import { IntegrationService } from "src/app/shared/services/integration/integration.service";
import { TagService } from "src/app/shared/services/tag/tag.service";
import { WorkflowService } from "src/app/shared/services/workflow/workflow.service";
import { setFormErrors } from "src/app/shared/utils/common";

export interface KickOffFormInterface {
  option: string;
}

export interface WorkflowFormInterface {
  // Workflow creation fields
  name: string;
  description: string;
  // Workflow category creation fields
  category?: number | null;
  category_name?: string;
  category_description?: string;
}

export interface WorkflowEditInterface {
  selectedIntegrations: WorkflowIntegration[];
  workflowForm: WorkflowFormInterface;
  type: WorkflowForm;
  kickOffForm: KickOffFormInterface;
}

const WORKFLOW_TYPES: WorkflowForm[] = [
  {
    name: "Create workflow",
    definition: [],
    creates: true,
  },
  {
    name: "Modify workflow",
    definition: [],
    creates: false,
  },
];

@Component({
  selector: "app-workflow-create",
  templateUrl: "./workflow-create.component.html",
  styleUrls: ["./workflow-create.component.scss"],
})
export class WorkflowCreateComponent implements OnInit {
  // Ui related
  isLoading: boolean = true;
  currentStep: number = 1;
  totalSteps: number = 4;
  isWorkflowsLoading: boolean = false;

  // Edit workflow
  workflowToEdit: Workflow | undefined = undefined;

  // Holds all the integration and its workers.
  integrations: WorkflowIntegration[] = [];

  // The submited data that comes from each step.
  workflowForm = new UntypedFormGroup({
    name: new UntypedFormControl("", [
      Validators.required,
      Validators.maxLength(255),
    ]),
    description: new UntypedFormControl("", [
      Validators.required,
      Validators.maxLength(10000),
    ]),
    category: new UntypedFormControl("", [Validators.maxLength(255)]),
  });

  type: WorkflowForm = {} as WorkflowForm;
  // It's only used to hightlight the selected workflow type.
  selectedTypeIndex: number | null = null;

  selectedIntegrations: WorkflowIntegration[] = [];
  kickOffForm: KickOffFormInterface = {} as KickOffFormInterface;

  constructor(
    private spinner: NgxSpinnerService,
    private integrationService: IntegrationService,
    private workflowService: WorkflowService,
    private tagService: TagService,
    private router: Router,
  ) {
    this.workflowToEdit =
      this.router.getCurrentNavigation()?.extras.state?.workflow;

    if (this.workflowToEdit) {
      this.currentStep =
        this.router.getCurrentNavigation()?.extras.state?.step || 1;
    }
  }

  ngOnInit(): void {
    // If this is a workflow edition action, make sure to pre-load the workflow data.
    Promise.all([
      this.workflowService.getWorkflowCategories(),
      this.workflowService.getWorkflows(),
      this.integrationService.getIntegrationWorkers(),
    ])
      .then(() => {
        this.integrations = this.integrationService.integrations;
        if (this.workflowToEdit) {
          this.editWorkflowParseData(this.workflowToEdit);
        }
      })
      .catch(() => {
        // TODO: Error handling
      })
      .finally(() => {
        this.spinner.hide();
        this.isWorkflowsLoading = false;
        this.isLoading = false;
      });
  }

  get title() {
    return this.workflowToEdit ? "Update a Workflow" : "Create a Workflow";
  }

  editWorkflowParseData = (workflow: Workflow) => {
    // 1) Workflow Form - Workflow Category Form
    this._initializeWorkflowForm(workflow);
    // 2) Workflow Type
    this._initializeTypeForm(workflow);
    // 3) Workflow integrations
    this._initializeSelectedWorkflowSteps(workflow);
    // 4) Kick off time
    // No need, theres already a 2 way binding there.
    // 5) Preview
    // No need to update anything.
  };

  _initializeSelectedWorkflowSteps = (workflow: Workflow) => {
    let workflowIntegrations: WorkflowIntegration[] = [];

    // Loop through all the workflow steps and build the Workflow Integration.
    for (let step of workflow.steps) {
      let worker: Worker = {} as Worker;
      // Find worker's tag, everything is based on worker's tag.
      let workerTag = this.tagService.tags.find((tag: Tag) => {
        return tag.id === step.tag;
      });
      if (!workerTag) throw new Error("Couldn't find steps tag");

      let integration: WorkflowIntegration | undefined;
      // There are two types of steps.
      // The manual ones and the automated / workers.
      // If the step is a manual one there are no worker, there are just a title / description and a tag.
      // No plugin tags / worker tags are expected.
      if (step.is_manual) {
        let w: Worker | undefined = this.integrationService.workers.find(
          (worker: Worker) => {
            return worker.name === GASPAR.CUSTOM_TICKET_WORKER;
          },
        );
        if (!w) throw new Error("Couldnt determine the worker itself");

        // This is a manual step.
        worker.name = w.name;
        worker.form_name = w.form_name;
        worker.tag = w.tag;
        worker.definition = w.definition;
        worker.form_values = step.form_values || {};
        worker.is_workflow_unique = w.is_workflow_unique;
        // Find the integration in order to add the worker under it.
        integration = this.integrations.find(
          (wIntegration: WorkflowIntegration) => {
            return (
              wIntegration.integration.code === GASPAR.CUSTOM_INTEGRATION_ACTION
            );
          },
        );
        if (!integration)
          throw new Error("Cooudln't determine the give workflow integration");
      } else {
        // The step is a worker step which means that there should be a worker tag.
        let w: Worker | undefined = this.integrationService.workers.find(
          (worker: Worker) => {
            return worker.tag && worker.tag.id === workerTag!.id;
          },
        );
        if (!w) throw new Error("Couldnt determine the worker itself");
        // There should be a plugin tag so we can determine on what integration the given worker
        // is going to work for.
        let pluginTag = this.tagService.tags.find((tag: Tag) => {
          return step.plugin && tag.id === step.plugin;
        });
        if (!pluginTag) throw new Error("Couldn't determine plugin tag");
        // Find the integration.
        integration = this.integrations.find(
          (wIntegration: WorkflowIntegration) => {
            return wIntegration.integration.code === pluginTag!.name;
          },
        );
        if (!integration)
          throw new Error("Cooudln't determine the give workflow integration");

        // Build the AA step
        worker.name = w.name;
        worker.form_name = w.form_name;
        worker.tag = w.tag;
        worker.definition = w.definition;
        worker.form_values = step.form_values || {};
        worker.is_workflow_unique = w.is_workflow_unique;
      }

      // Build the integration
      let workflowIntegration: WorkflowIntegration = {} as WorkflowIntegration;

      // Integration exists in the UI?
      let exists = workflowIntegrations.some((item: WorkflowIntegration) => {
        return item.integration.code === integration!.integration.code;
      });
      // If it exists, just push the worker under it.
      // Otherwise append a totally new integration with the recently parsed worker.
      if (exists) {
        let int = workflowIntegrations.find((item: WorkflowIntegration) => {
          return item.integration.code === integration!.integration.code;
        });
        if (!int)
          throw new Error("Couldn't determine where to add the worker...");

        int.workers.push(worker);
      } else {
        workflowIntegration = {
          integration: integration.integration,
          workers: [worker],
          is_active: true,
        };
        workflowIntegrations.push(workflowIntegration);
      }
    }
    // Assign the builded integrations into the referenced property.
    this.selectedIntegrations = workflowIntegrations;
  };

  _initializeWorkflowForm = (workflow: Workflow) => {
    let workflowForm: WorkflowFormInterface = {} as WorkflowFormInterface;

    workflowForm = {
      name: workflow.title,
      description: workflow.description || "",
      category: workflow.category,
    };

    this.workflowForm.setValue(workflowForm);
  };

  _initializeTypeForm = (workflow: Workflow) => {
    let types = this.workflowForms;

    // TODO: Mind that we don't have a model for the workflow types.
    // It's totally based on the workflow forms which at this point
    // we just know if the form is used for creation or modification.
    for (let i = 0; i < types.length; i++) {
      if (workflow.creates === types[i].creates) {
        this.type = types[i];
        this.selectedTypeIndex = i;
        break;
      }
    }
    if (this.selectedTypeIndex === null)
      throw new Error("Couldn't determine the workflow type!");
  };

  get workflowForms() {
    return WORKFLOW_TYPES;
  }

  onBackStep = () => {
    if (this.currentStep > 1) this.currentStep--;
  };

  incrementStep = () => {
    if (this.currentStep < this.totalSteps) this.currentStep++;
  };

  onWorkflowSave(workflowForm: UntypedFormGroup) {
    this.workflowForm = workflowForm;
    this.incrementStep();
  }

  onTypeSave(data: WorkflowForm) {
    this.type = data;
    this.incrementStep();
  }

  onIntegrationsSave(data: WorkflowIntegration[]) {
    this.selectedIntegrations = data;
    this.incrementStep();
  }

  onKickOffSave(data: KickOffFormInterface) {
    this.kickOffForm = data;
    this.incrementStep();
  }

  _createWorkflowCategory = (workflowValues: WorkflowFormInterface) => {
    if (!workflowValues.category_name || !workflowValues.category_description)
      throw new Error(
        "No category name or description while creating new category",
      );

    this.workflowService
      .createWorkflowCategory({
        name: workflowValues.category_name,
        description: workflowValues.category_name,
      })
      .then((result: WorkflowCategory) => {
        if (!result.id) throw new Error("Couldn't find category id.");

        this._createOrUpdateWorkflow(result.id);
      })
      .catch(err => {
        this.currentStep = 1;
        setFormErrors(err.errors, this.workflowForm);
      })
      .finally(() => {
        this.spinner.hide();
      });
  };

  _createOrUpdateWorkflowCategory = () => {
    this.spinner.show();

    // Create a new category if needed
    let workflowValues = this.workflowForm.value;
    if (workflowValues.category_name && workflowValues.category_description) {
      this._createWorkflowCategory(workflowValues);
    } else {
      this._createOrUpdateWorkflow(workflowValues.category || null);
    }
  };

  _createOrUpdateWorkflow = (categoryId: number | null) => {
    let steps: WorkflowStep[] = [];
    for (let integration of this.selectedIntegrations) {
      // Custom steps expects slightly different request.
      let isCustomStep: boolean =
        integration.integration.code === GASPAR.CUSTOM_INTEGRATION_ACTION
          ? true
          : false;

      let pluginTag = this.tagService.tags.find((tag: Tag) => {
        return tag.name === integration.integration.code;
      });

      for (let worker of integration.workers) {
        let step: WorkflowStep = {} as WorkflowStep;

        if (isCustomStep) {
          let customTag: number | undefined = this.tagService.tags.find(
            (tag: Tag) => {
              return tag.name === worker.form_values!["tag"];
            },
          )?.id;
          if (!customTag) throw new Error("Couldn't find custom tag!!!");

          step.title = worker.form_values!["title"];
          step.description = worker.form_values!["description"];
          step.is_manual = true;
          step.bypass_approvals = true;
          step.tag = customTag;
          step.form_values = worker.form_values || {};
          step.plugin = null;
        } else {
          if (!worker.tag?.id) throw new Error("Coouldnt find worker tag!");
          if (!pluginTag) {
            throw new Error("Couldn't find Plugin Tag");
          }
          step.title = "";
          step.description = "";
          step.is_manual = false;
          step.bypass_approvals = true;
          step.tag = worker.tag.id;
          step.form_values = worker.form_values || {};
          step.plugin = pluginTag.id;
        }
        steps.push(step);
      }
    }

    let workflowValues: WorkflowFormInterface = this.workflowForm.value;
    let workflowToCreateOrUpdate: Workflow = {
      title: workflowValues.name,
      description: workflowValues.description,
      creates: this.type.creates,
      is_active: true,
      steps: steps,
      category: categoryId || null,
    };

    // Edit mode.
    if (this.workflowToEdit) {
      workflowToCreateOrUpdate.id = this.workflowToEdit.id;

      this.workflowService
        .updateWorkflow(workflowToCreateOrUpdate)
        .then(() => {
          this.router.navigateByUrl("workflows");
        })
        .catch(err => {
          this.currentStep = 1;
          setFormErrors(err.errors, this.workflowForm);
        })
        .finally(() => {
          this.spinner.hide();
        });
    } else {
      // Create mode
      this.workflowService
        .createWorkflow(workflowToCreateOrUpdate)
        .then(() => {
          this.router.navigateByUrl("workflows");
        })
        .catch(err => {
          this.currentStep = 1;
          setFormErrors(err.errors, this.workflowForm);
        })
        .finally(() => {
          this.spinner.hide();
        });
    }
  };

  onReviewSave(data: boolean) {
    if (!data) throw new Error("Review did not return a valid value!");
    this._createOrUpdateWorkflowCategory();
  }
}
