import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { URLS } from "../../constants/urls";
import { IPaginatedUser, User, UserRole } from "../../models/user";
import { ServiceModule } from "../service.module";

@Injectable({
  providedIn: "root",
})
export class UserService extends ServiceModule {
  usersSubject: Subject<any> = new Subject();

  _user: User = {} as User;
  _users: User[] = [];

  constructor(private http: HttpClient) {
    super();
  }

  get user() {
    return this._user;
  }

  getSelf = () => {
    // Get's the current user
    return this.http
      .get<User>(URLS.USER_ME)
      .toPromise()
      .then((user: User) => {
        this._user = user;
        return user;
      });
  };

  get users() {
    return this._users;
  }

  getUsers = (role: string, is_active: boolean, page: number) => {
    // Retrieve all the available users
    let query = "?";
    if (role) {
      query += `&role=${role}`;
    }
    if (is_active) {
      query += `&is_active=${is_active}`;
    }

    return this.http
      .get<IPaginatedUser>(URLS.USER + `${query}&page=${page}`)
      .toPromise()
      .then((users: IPaginatedUser) => {
        return users;
      });
  };

  updateUserTags = (userId: number, tags: number[]) => {
    // Update user's expertise tags
    return this.http
      .patch<User>(`${URLS.USER}${userId}/`, { tags })
      .toPromise()
      .then((user: User) => {
        const index = (this.users || []).findIndex(item => item.id === userId);
        if (index > -1) {
          this.users[index] = user;
        }
        this.usersSubject.next(this.users);
      });
  };

  updateUserRole = (userId: number, role: UserRole) => {
    return this.http.patch(`${URLS.USER}${userId}/`, { role }).toPromise();
  };

  searchUser = (query: string) => {
    return this.http
      .get<User[]>(`${URLS.USER_SEARCH}?query=${query}`)
      .toPromise()
      .then((users: User[]) => {
        return users;
      });
  };
}
