import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Location } from "@angular/common";
import {
  KickOffFormInterface,
  WorkflowFormInterface,
} from "../../workflow-create/workflow-create.component";
import {
  WorkflowCategory,
  WorkflowForm,
  WorkflowIntegration,
} from "src/app/shared/models/workflow";
import { WorkflowService } from "src/app/shared/services/workflow/workflow.service";
import { Worker } from "src/app/shared/models/worker";
import { UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "app-workflow-review-step",
  templateUrl: "./workflow-review-step.component.html",
  styleUrls: ["./workflow-review-step.component.scss"],
})
export class WorkflowReviewStepComponent implements OnInit {
  @Input() active: boolean = false;
  // All the data that comes from prior completed steps.
  @Input() workflowForm: UntypedFormGroup = {} as UntypedFormGroup;
  @Input() type: WorkflowForm = {} as WorkflowForm;
  @Input() selectedIntegrations: WorkflowIntegration[] = [];
  @Input() kickOffForm: KickOffFormInterface = {} as KickOffFormInterface;

  @Output() submitEvent = new EventEmitter();
  @Output() backEvent = new EventEmitter();

  constructor(
    private location: Location,
    private workflowService: WorkflowService,
  ) {}

  ngOnInit(): void {}

  get selectedFilteredIntegrations() {
    return this.selectedIntegrations.filter(
      (wIntegration: WorkflowIntegration) => {
        return wIntegration.workers.length > 0;
      },
    );
  }

  workerFormKeys = (worker: Worker) => {
    if (worker.form_values) {
      return Object.keys(worker.form_values);
    } else return [];
  };

  workerFormValues = (worker: Worker) => {
    if (worker.form_values) {
      return Object.values(worker.form_values);
    } else return [];
  };

  get workflowCategoryName() {
    let workflowValues: WorkflowFormInterface = this.workflowForm.value;

    if (workflowValues.category) {
      let cat_name: WorkflowCategory | undefined =
        this.workflowService.workflowCategories.find(
          (item: WorkflowCategory) => {
            return item.id === workflowValues.category;
          },
        );
      if (!cat_name)
        throw new Error("Couldn't determine workflow's category name");

      return cat_name.name;
    } else if (workflowValues.category_name) {
      return workflowValues.category_name;
    } else {
      return "Uncategorized workflow";
    }
  }
  onCancel = () => {
    this.location.back();
  };

  onBack = () => {
    this.backEvent.emit();
  };

  onReviewSave(): void {
    this.submitEvent.emit(true);
  }
}
