<div class="content">
  <span class="content-title">What would you like to create?</span>
  <div class="content-container">
    <div class="box pointer" (click)="navigateToWorkflowCategoryCreation()">
      <div class="content" i18n="workflows:workflow create category">
        <img src="assets/images/workflow/workflow-category.svg" />
        Create a Category
      </div>
    </div>
    <div class="box pointer">
      <div
        class="content"
        i18n="workflows:workflow create"
        (click)="navigateToWorkflowCreation()"
      >
        <img src="assets/images/workflow/workflow.svg" />
        Create a Workflow
      </div>
    </div>
  </div>
</div>
