<div
  [class.overlay]="active"
  *ngIf="active"
  [@fade]="active"
  (click)="deactivateOverlay()"
>
  <div (click)="cancelInnerProp($event)">
    <ng-content></ng-content>
  </div>
</div>
