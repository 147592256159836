<div class="top-level-statistics-container">
  <app-box
    title="Conversations"
    image="assets/images/dashboard/conversations.svg"
    [totalNumber]="data.total_conversations"
    color="#5CC285"
  >
  </app-box>
  <app-box
    title="Auto-Resolved Tickets"
    image="assets/images/dashboard/automated.svg"
    [totalNumber]="data.aa_accepted"
    color="#4569F7"
  >
  </app-box>
  <app-box
    title="Diverted Tickets"
    image="assets/images/dashboard/diverted.svg"
    [totalNumber]="data.total_deflected"
    color="#4569F7"
  >
  </app-box>
  <app-box
    title="Manual Tickets"
    image="assets/images/dashboard/opened.svg"
    [totalNumber]="data.total_manual_tickets"
    color="#4569F7"
  >
  </app-box>
  <app-box
    title="Abandoned Conversations"
    image="assets/images/dashboard/abandoned.svg"
    [totalNumber]="data.total_abandoned"
    color="#4569F7"
  >
  </app-box>
</div>
