<div class="content" [class.blur]="isLoading">
  <div class="integration-title">
    <img
      src="{{ imageBaseUrl + integration.icon_enabled }} "
      alt="integration logo"
      *ngIf="info?.is_active"
    />
    <img
      src="{{ imageBaseUrl + integration.icon_disabled }} "
      alt="integration logo"
      *ngIf="!info?.is_active"
    />
    <div
      class="integration-name wrap-text"
      *ngIf="shouldShowTitle(integration.code)"
    >
      {{ integration.name }}
    </div>
  </div>
  <div class="integration-status">
    <markdown [data]="info?.detail_message"></markdown>
  </div>
  <button
    class="button-status"
    *ngIf="showEnableButton(integration.code)"
    mat-raised-button
    color="primary"
    (click)="onIntegrationEnable()"
    i18n="integration-component:Enable button"
    [class.spinner]="isIntegrationButtonLoading"
    [disabled]="isIntegrationButtonLoading"
  >
    Enable
  </button>
  <button
    class="button-reset-config"
    *ngIf="showResetConfigurationButton(integration.code)"
    mat-raised-button
    color="warn"
    (click)="onIntegrationDisable()"
    i18n="integration-component:Reconfigure button"
    [class.spinner]="isIntegrationButtonLoading"
    [disabled]="isIntegrationButtonLoading"
  >
    Reconfigure
  </button>
  <button
    mat-button
    class="google-btn"
    (click)="onIntegrationEnable()"
    *ngIf="isGoogleActive(integration.code)"
    i18n="integration-component:Sign in with google button"
    [class.spinner]="isIntegrationButtonLoading"
    [disabled]="isIntegrationButtonLoading"
  >
    <img src="assets/images/login/google-logo.svg" alt="google" />
    Sign in with Google
  </button>
  <button
    class="button-status"
    mat-raised-button
    color="warn"
    (click)="onIntegrationDisable()"
    *ngIf="showDisableButton()"
    i18n="integration-component:Disable button"
    [class.spinner]="isIntegrationButtonLoading"
    [disabled]="isIntegrationButtonLoading"
  >
    Disable
  </button>

  <div class="warning-status" *ngIf="showWarningInfo()">
    <img src="assets/images/info-red.svg" alt="integration" />
    <span class="warning-text">
      Something went wrong with this plugin. Please press the button to turn it
      on again!
    </span>
  </div>
  <div *ngIf="error">
    <span class="warning-text mt centered">
      {{ error }}
    </span>
  </div>
  <div class="integration-config-button" *ngIf="showConfig && !error">
    <mat-accordion hideToggle>
      <div
        class="pointer"
        (click)="togglePanel()"
        i18n="integration-component:Config button"
      >
        Configure <fa-icon [icon]="arrowUp" *ngIf="panelOpenState"></fa-icon>
        <fa-icon [icon]="arrowDown" *ngIf="!panelOpenState"></fa-icon>
      </div>
      <div class="gaspar-overlay-container">
        <mat-expansion-panel
          [expanded]="panelOpenState"
          [class.gaspar-card]="panelOpenState"
          [class.hidden]="!panelOpenState"
          class="overlay"
        >
          <!-- ngIf is required so we can assume that the config exists in the child component -->
          <app-form
            *ngIf="config.definition"
            [definition]="config.definition"
            [values]="config.values"
            (submitEvent)="onConfigSubmit($event)"
            [isSubmitLoading]="isSubmitButtonLoading"
          ></app-form>
        </mat-expansion-panel>
      </div>
    </mat-accordion>
  </div>
  <button
    class="button-trigger-manual-sync-knowledge"
    *ngIf="showManualSyncKnowledgeButton()"
    mat-raised-button
    color="basic"
    (click)="onManulSyncKnowledge()"
    i18n="integration-component:Trigger Manual Sync Knowledge"
    [class.spinner]="isIntegrationButtonLoading"
    [disabled]="isIntegrationButtonLoading"
  >
    Manual Sync
  </button>
  <button
    class="button-trigger-manual-sync-knowledge"
    *ngIf="showManualSyncKnowledgeInProgress()"
    mat-raised-button
    color="disabled"
    i18n="integration-component:Manual Sync Knowledge In Progress"
    [disabled]="true"
  >
    Syncing...
  </button>
</div>
