import { Component, OnInit } from "@angular/core";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { WorkflowService } from "src/app/shared/services/workflow/workflow.service";
import { NgxSpinnerService } from "ngx-spinner";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { WorkflowCreateModalComponent } from "./workflow-create-modal/workflow-create-modal.component";
import { Workflow, WorkflowCategory } from "src/app/shared/models/workflow";
import { ModalService } from "src/app/shared/services/modal/modal.service";
import { RunWorkflowModalComponent } from "./components/run-workflow-modal/run-workflow-modal.component";
import { IntegrationService } from "src/app/shared/services/integration/integration.service";

@Component({
  selector: "app-workflows",
  templateUrl: "./workflows.component.html",
  styleUrls: ["./workflows.component.scss"],
})
export class WorkflowsComponent implements OnInit {
  selectedTab: string = "active"; // ["active", "archived"]
  isWorkflowsLoading: boolean = false;
  ellipsisIcon = faEllipsisV;
  searchForm = new UntypedFormGroup({
    search: new UntypedFormControl(""),
  });

  constructor(
    private router: Router,
    private workflowService: WorkflowService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private modalService: ModalService,
    private integrationService: IntegrationService,
  ) {}

  ngOnInit(): void {
    this.isWorkflowsLoading = true;
    this.spinner.show();
    Promise.all([
      this.integrationService.getIntegrationWorkers(),
      this.workflowService.getWorkflowCategories(),
      this.workflowService.getWorkflows(),
    ])
      .catch(() => {
        // TODO: Error handling
      })
      .finally(() => {
        this.spinner.hide();
        this.isWorkflowsLoading = false;
      });
  }

  get hasWorkflows(): boolean {
    return this.workflows.length > 0;
  }

  get hasCategories(): boolean {
    return this.categories.length > 0;
  }

  get categories() {
    return this.workflowService.workflowCategories.filter(
      (workflow_category: WorkflowCategory) => {
        return this.activeTabSelected
          ? workflow_category.is_active
          : !workflow_category.is_active;
      },
    );
  }

  get workflows() {
    // While searching...
    if (this.searchForm.controls.search.value.trim()) {
      return this.workflowService.workflows.filter((workflow: Workflow) => {
        return (
          (this.activeTabSelected ? workflow.is_active : !workflow.is_active) &&
          workflow.title
            .toLowerCase()
            .includes(
              this.searchForm.controls.search.value.toLowerCase().trim(),
            )
        );
      });
    } else {
      // Default uncategorized workflows.
      return this.workflowService.workflows.filter((workflow: Workflow) => {
        return this.activeTabSelected
          ? workflow.is_active
          : !workflow.is_active;
      });
    }
  }

  get uncategorizedWorkflows() {
    return this.workflowService.workflows.filter((workflow: Workflow) => {
      return this.activeTabSelected
        ? workflow.is_active && !workflow.category
        : !workflow.is_active && !workflow.category;
    });
  }

  get activeTabSelected() {
    return this.selectedTab === "active";
  }

  onSelectedTagChange = (value: string): boolean => {
    return this.selectedTab === value;
  };

  onChangeTag = (value: string): void => {
    this.selectedTab = value;
  };

  hasCategoryWorkflows = (category: WorkflowCategory): boolean => {
    return this.workflows.some((item: Workflow) => {
      return item.category === category.id;
    });
  };

  goToCategoryDetails = (category: WorkflowCategory) => {
    if (this.hasCategoryWorkflows(category))
      this.router.navigateByUrl(`workflow/category/${category.id}`);
  };

  // Workflow Actions
  onWorkflowEditClicked = (workflow: Workflow) => {
    this.router.navigateByUrl("workflow/update", {
      state: { workflow: workflow },
    });
  };

  onWorkflowDuplicateClicked = (workflow: Workflow) => {
    this.spinner.show();
    this.isWorkflowsLoading = true;

    this.workflowService
      .createWorkflow(workflow)
      .then((workflow: Workflow) => {
        this.router.navigateByUrl("workflow/update", {
          state: { workflow: workflow, step: 4 },
        });
      })
      .catch(() => {
        // TODO: error handling.
      })
      .finally(() => {
        this.spinner.hide();
        this.isWorkflowsLoading = false;
      });
  };

  workflowModalKey = (workflow: Workflow): string => {
    return `workflow_${workflow.id!.toString()}`;
  };

  onWorkflowInfoClicked = (workflow: Workflow) => {
    this.modalService.open(this.workflowModalKey(workflow));
  };

  onWorkflowToggleArchiveClicked = (workflow: Workflow) => {
    workflow.is_active = !workflow.is_active;
    this.workflowService
      .updateWorkflow(workflow)
      .then(() => {
        // TODO: Success message
      })
      .catch(() => {
        // TODO: error message
      });
  };

  // Workflow Category Actions
  onWorkflowCategoryEditClicked = (category: WorkflowCategory) => {
    this.router.navigateByUrl("workflow/category/update", {
      state: { workflow_category: category },
    });
  };

  categoryModalKey = (category: WorkflowCategory): string => {
    return `category_${category.id!.toString()}`;
  };

  onWorkflowCategoryInfoClicked = (category: WorkflowCategory) => {
    this.modalService.open(this.categoryModalKey(category));
  };

  onWorkflowCategoryToggleArchiveClicked = (category: WorkflowCategory) => {
    category.is_active = !category.is_active;
    this.workflowService
      .updateWorkflowCategory(category)
      .then(() => {
        // TODO: Success message.
      })
      .catch(() => {
        // TODO: Error message
      });
  };

  getActiveWorkflowsForCategory = (
    category: WorkflowCategory,
  ): string | number => {
    // If no workflows found, return a "No" in order to show it in the UI, otherwise returns the number
    // of the active workflows for the given category
    let numOfWorkflows: number = 0;
    this.workflows.filter((workflow: Workflow) => {
      if (
        workflow.category &&
        workflow.is_active &&
        workflow.category == category.id
      ) {
        numOfWorkflows += 1;
      }
    });
    if (numOfWorkflows) return numOfWorkflows;
    else return "No";
  };

  navigateToWorkflowCategoryCreation() {
    this.router.navigateByUrl("workflow/category/create");
  }

  navigateToWorkflowCreation() {
    this.router.navigateByUrl("workflow/create");
  }

  runWorkflowModal = (workflow: Workflow): void => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "gaspar-modal";
    dialogConfig.data = {
      workflow: workflow,
    };
    this.dialog.open(RunWorkflowModalComponent, dialogConfig);
  };

  onCreateWorkflowModal = (): void => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "gaspar-modal-large";
    this.dialog.open(WorkflowCreateModalComponent, dialogConfig);
  };
}
