import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GASPAR } from "src/app/shared/constants/gaspar";
import { Integration } from "src/app/shared/models/integration";
import { WorkflowIntegration } from "src/app/shared/models/workflow";
import { ModalService } from "src/app/shared/services/modal/modal.service";

@Component({
  selector: "app-integrations-modal",
  templateUrl: "./integrations-modal.component.html",
  styleUrls: ["./integrations-modal.component.scss"],
})
export class IntegrationsModalComponent implements OnInit {
  @Input() integrations: WorkflowIntegration[] = [];
  @Input() editingWorkflowIntegrations: WorkflowIntegration[] = [];
  @Input() id: string = "";
  @Output() submitEvent = new EventEmitter();

  selectedIntegrations: Integration[] = [];
  panelOpenState: boolean[] = [];

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {
    // Some kind of UI initialization in order to handle multiple dropdowns.
    this.initializePanelState();
  }

  get filteredIntegrations() {
    let integrations = this.integrations.filter((item: WorkflowIntegration) => {
      // make sure the integration hasn't already been added.
      return !this.editingWorkflowIntegrations.some(
        (itemExists: WorkflowIntegration) => {
          return itemExists.integration.code === item.integration.code;
        },
      );
    });
    return integrations;
  }

  initializePanelState = () => {
    for (let i = 0; i < this.integrations.length; i++) {
      this.panelOpenState[i] = false;
    }
  };

  getPanelOpenState(index: number): boolean {
    return this.panelOpenState[index];
  }

  togglePanelState(index: number): void {
    this.panelOpenState[index] = !this.panelOpenState[index];
  }

  nonTitleIntegration = (integration: WorkflowIntegration): boolean => {
    return GASPAR.NON_TITLE_INTEGRATIONS.includes(integration.integration.name);
  };

  closeModal = () => {
    this.modalService.close(this.id);
  };

  onNext = () => {
    this.submitEvent.emit(this.selectedIntegrations);
    this.modalService.close(this.id);
  };

  onCheckboxSelect = (event: any, integration: Integration) => {
    if (event.checked) {
      this.selectedIntegrations.push(integration);
    } else {
      this.selectedIntegrations = this.selectedIntegrations.filter(
        (item: Integration) => {
          return item.code !== integration.code;
        },
      );
    }
  };

  getWorkersForIntegration = (integration: Integration) => {
    let wantedIntegration = this.integrations.find(
      (workflowIntegration: WorkflowIntegration) => {
        return workflowIntegration.integration.code == integration.code;
      },
    );
    return wantedIntegration!.workers;
  };
}
