<div
  class="tag"
  [matTooltip]="tag.description"
  matTooltipClass="tooltip"
  [style.color]="tag.type.color"
  [class.disabled]="!tag.is_active"
  [class.non-selectable]="disabled"
  (click)="onTagClick(tag)"
>
  <div class="tag-background" [style.background]="tag.type.color"></div>
  <div class="flex align-center">
    {{ name }}
    <ng-content></ng-content>
  </div>
</div>
