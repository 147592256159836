<div *ngIf="!isLoading">
  <span i18n="integrations:integration title" class="gaspar-title"
    >Integrations</span
  >
  <div class="integrations-container">
    <div
      class="integration-card"
      [class.has-error]="this.errorsOnIntegration.includes(integration.code)"
      *ngFor="let integration of integrations"
    >
      <app-integration
        [integration]="integration"
        (hasError)="hasErrorEmmiter($event)"
      ></app-integration>
    </div>
  </div>
</div>
