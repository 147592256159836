import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { URLS } from "../../constants/urls";
import { MetricsResolution } from "../../models/metrics";
import { ServiceModule } from "../service.module";

@Injectable({
  providedIn: "root",
})
export class MetricsService extends ServiceModule {
  constructor(private http: HttpClient) {
    super();
  }

  getLastDaysResolutionMetrics(days: number | string) {
    // Given the number of the last X days, find the date.

    if (typeof days == "string") days = parseInt(days);

    let startinDate = moment().subtract(days, "d").format("YYYY-MM-DD");
    let endingDate = moment().subtract(1, "d").format("YYYY-MM-DD");

    // Initialize Params Object
    let params = new HttpParams();
    params = params.append("start_date", startinDate);
    params = params.append("end_date", endingDate);

    return this.http
      .get<MetricsResolution>(URLS.METRICS, { params: params })
      .toPromise()
      .then((results: MetricsResolution) => {
        return results;
      });
  }
}
