import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { SidebarStatusEnum } from "src/app/shared/components/sidebar/sidebar.component";
import { GASPAR } from "src/app/shared/constants/gaspar";
import { STORAGE_KEYS } from "src/app/shared/constants/localeStorageKeys";
import { TokenExchange } from "src/app/shared/models/authentication";
import { AuthenticationService } from "src/app/shared/services/authentication/authentication.service";
import { TagService } from "src/app/shared/services/tag/tag.service";
import { UserService } from "src/app/shared/services/user/user.service";

@Component({
  selector: "app-redirect",
  templateUrl: "./redirect.component.html",
  styleUrls: ["./redirect.component.scss"],
})
export class RedirectComponent implements OnInit {
  private sub: any;
  loading: boolean = true;
  error: string = "";

  constructor(
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private userService: UserService,
    private tagService: TagService,
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.sub = this.route.queryParams.subscribe((params: Params) => {
      if (!params.code || !params.state) {
        throw new Error(
          `Either code or state doesn't exist in redirect params: ${params}`,
        );
      }
      this.authService
        .tokenExchange(params.code, params.state)
        .then(async (result: TokenExchange) => {
          if (!result.token)
            throw new Error("Didn't receive a token, can't proceed sorry");

          Promise.all([
            this.userService.getSelf(),
            this.tagService.getTags(),
            this.tagService.getTagTypes(),
          ])
            .then(() => {
              this.router.navigateByUrl(GASPAR.DEFAULT_SCREEN);
              this.spinner.hide();
              localStorage.setItem(
                STORAGE_KEYS.SIDEBAR,
                SidebarStatusEnum.OPEN,
              );
            })
            .catch(() => {
              localStorage.removeItem(STORAGE_KEYS.TOKEN);
              this.router.navigateByUrl("");
            });
        })
        .catch(err => {
          this.error = err.error?.detail || err.error;
        })
        .finally(() => {
          this.loading = false;
          this.spinner.hide();
        });
    });
  }

  onBack = (): void => {
    this.router.navigateByUrl("");
  };
}
