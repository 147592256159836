import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { STORAGE_KEYS } from "../constants/localeStorageKeys";
import { baseUrl } from "../utils/common";

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class ServiceModule {
  private _backend_url: string = "";
  private _token: string | null = null;

  constructor() {
    this.backend_url = baseUrl();
    this.migrateOldToken();
    this.token = this.getToken();
  }

  /* Backend Url */
  set backend_url(url: string) {
    this._backend_url = url;
  }

  get backend_url() {
    return this._backend_url;
  }

  /* Token */
  set token(token: string | null) {
    this._token = token;
  }

  get token() {
    return this._token;
  }

  getToken = (): string | null => {
    // Returns the current token from the local storage
    return localStorage.getItem(STORAGE_KEYS.TOKEN);
  };

  migrateOldToken = () => {
    let oldToken = localStorage.getItem(STORAGE_KEYS.OLD_TOKEN);
    if (oldToken) {
      localStorage.setItem(STORAGE_KEYS.TOKEN, oldToken);
      localStorage.removeItem(STORAGE_KEYS.OLD_TOKEN);
    }
  };
}
