import { Component, HostListener, Input, OnInit } from "@angular/core";
import { ChartType } from "angular-google-charts";
import { STORAGE_KEYS } from "src/app/shared/constants/localeStorageKeys";
import { WorkerMetricsInterface } from "src/app/shared/models/metrics";
import {
  calculateGraphItemHeight,
  calculateAllGraphHeight,
} from "src/app/shared/utils/common";

const SIDEBAR_WIDTH: number = 240;
const PAGE_MARGINS: number = 35;

@Component({
  selector: "app-missed-opportunity-chart",
  templateUrl: "./missed-opportunity-chart.component.html",
  styleUrls: ["./missed-opportunity-chart.component.scss"],
})
export class MissedOpportunityChartComponent implements OnInit {
  @Input() data: WorkerMetricsInterface = {} as WorkerMetricsInterface;

  graphData: [string, number][] = [];
  title: string = "";
  columnNames = ["Query", "Value"];
  width: any = 0;
  height: any = 350;
  options = {
    colors: ["#9FC7EB"],
    fontSize: 12,
    legend: {
      position: "none",
    },
    hAxis: {
      minValue: 0,
      format: "0",
    },
    chartArea: {
      height: 0,
    },
  };
  type: ChartType = ChartType.BarChart;

  constructor() {
    this.width = this.calcInnerWidth();
  }

  ngOnInit(): void {
    for (let key in this.data.missed_opportunities) {
      this.graphData.push([key, this.data.missed_opportunities[key]]);
    }
    this.title = `Top ${this.graphData.length} unanswered questions.`;

    this.options.chartArea.height = calculateGraphItemHeight(this.graphData);
    this.height = calculateAllGraphHeight(this.graphData);
    this.graphData.sort((a, b) => a[1] - b[1]);
  }

  calcInnerWidth = (): number => {
    // Calculates the inner width for a responsive graph.
    if (localStorage.getItem(STORAGE_KEYS.SIDEBAR) == "close")
      return window.innerWidth - PAGE_MARGINS;
    else return window.innerWidth - SIDEBAR_WIDTH - PAGE_MARGINS;
  };

  @HostListener("window:resize", ["$event"])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onWindowResize(event: any) {
    this.width = this.calcInnerWidth();
  }
}
